.person-overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
}

.person-overlay__scroller {
  max-height: 100vh;
  overflow-y: auto;
}

.person-overlay__inner {
  position: relative;
  background-color: rgba(255, 255, 255, 0.7);
  max-width: 1000px;
  height: auto;
  border: 1px solid rgba(217, 217, 217, 0.6);
  border-radius: 5px;
  backdrop-filter: blur(10px);
  display: grid;
  grid-template-columns: 3fr 2fr;
  margin: 0 64px;

  @include mq($until: l) {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    margin: 0;
  }
}

.person-overlay__button-close {
  position: absolute;
  top: 16px;
  right: 16px;
}

.person-overlay__content {
  padding: 50px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  gap: 15px;
  color: $color-green-dark;

  @include mq($until: l) {
    padding-top: 64px;
    padding-bottom: 200px;
  }
}

.person-overlay__resort-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: start;
  align-items: center;
  gap: 8px;

}

.person-overlay__resort-list-item {
  position: relative;
  width: fit-content;
  padding: 7.5px 12px;
}

.person-overlay__resort-list-item::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  @include skew;
  background-color: $color-green-light-bg;
  z-index: -1;
  border-radius: 3px 5px;
}

.person-overlay__resort-list-item-name {
  font-weight: $font-weight-bold;
  font-size: $font-size-xxs;
}

.person-overlay__name {
  font-family: $root-font-headline;
  font-size: $font-size-xl;
}

.person-overlay__text {
  font-family: $root-font-sans;
  font-size: $font-size-xxs;
  line-height: $line-height-default;

}

.person-overlay__extras {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  gap: 16px;

  @include mq($until: l) {
    flex-direction: column;
    align-items: start;
  }
}

.extras__button-read-more {
  font-weight: $font-weight-bold;
}

.extras__socials {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  gap: 8px;

  @include mq($until: m) {
    flex-direction: column;
  }
}

.person-overlay__picture-container {
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: end;
}

.person-overlay__picture {
  height: 317px;
  width: 372px;

  @include mq($until: l) {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: -1;
  }
}
