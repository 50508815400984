$radio-size: 2rem;

.radio {
  display: inline-flex;
  font-size: $font-size-small;
  position: relative;
  width: 100%;
  flex-wrap: wrap;

  &:focus-within {
    @include focus-border();
  }
}

.radio__input {
  @include hidden-visually();

  height: $radio-size;
  width: $radio-size;
}

.radio__indicator {
  background-color: #fff;
  border: 2px solid $color-green-dark-bg;
  border-radius: 50%;
  flex-shrink: 0;
  height: $radio-size;
  position: relative;
  transition-duration: $fast-transition-duration;
  transition-property: background-color, border-color;
  user-select: none;
  width: $radio-size;

  &::before {
    background-color: #fff;
    border-radius: 50%;
    content: '';
    height: calc(#{$radio-size} * 0.25);
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: calc(#{$radio-size} * 0.25);
  }

  .radio__input:checked + & {
    background-color: #fff;
    //border-color: $color-green-light;
  }

  .radio__input:checked + &::before {
    background-color: $color-green-dark;
  }

  .radio__input:not(:checked):not([disabled]):hover + &::before {
    background-color: $color-gray;
  }

  .radio__input[aria-invalid='true'] ~ & {
    border-color: $color-red;
  }

  .radio__input[aria-invalid='true']:checked ~ & {
    //background-color: $color-red;
  }

  .radio__input[readonly] ~ &,
  .radio__input[disabled] ~ & {
    background-color: $color-greenwhite-dark;
    //border-color: $color-gray;
  }

  .radio__input[readonly]:not(:checked) ~ &::before,
  .radio__input[disabled]:not(:checked) ~ &::before {
    opacity: 0;
  }
}

.radio__label {
  line-height: $line-height-default;
  margin-left: 1rem;
  color: $color-green-dark;
  font-weight: 700;
  font-size: $font-size-xxs;

  .radio__input:checked ~ & {
    //color: $color-blue;
  }

  .radio__input[aria-invalid='true'] ~ & {
    //color: $color-red;
  }

  .radio__input[disabled] ~ & {
    color: $color-gray;
  }
}

.radio__error-message {
  width: 100%;
  flex: 0 0 100%;
  color: $color-red;
  display: none;
  margin-top: 10px;
  margin-bottom: 10px;
  position: relative;
}

.radio__input[aria-invalid='true'] ~ .radio__error-message {
  display: block;
}

.radio__indicator,
.radio__label {
  cursor: pointer;

  .radio__input[readonly] ~ & {
    cursor: default;
  }

  .radio__input[disabled] ~ & {
    cursor: not-allowed;
  }
}
