.label {
  display: block;
}

.label--invalid {
  color: $color-red;
}

.label--disabled {
  cursor: not-allowed;
}
