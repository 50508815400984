.preview {
  padding: 2rem;
}

.preview--paddingless {
  padding: 0;
}

.preview-buffer {
  min-height: 100vh;
}

.preview--overflow {
  overflow: visible;
  margin-top: 20rem;
  margin-left: 20rem;
}
