$spinner-bar-max-height: 6rem;

.spinner__bars {
  align-items: center;
  display: flex;
  gap: 0.5rem;
  height: 100%;
  justify-content: center;
  min-height: $spinner-bar-max-height;
  width: 100%;
}

.spinner__bar {
  animation-duration: 1000ms;
  animation-iteration-count: infinite;
  animation-name: spinner-jump;
  background-color: $color-green-light;
  display: block;
  height: 4rem;
  width: 1.5rem;
  will-change: height;
}

.spinner__bar:nth-child(2) {
  animation-delay: 150ms;
}

.spinner__bar:nth-child(3) {
  animation-delay: 300ms;
}

@keyframes spinner-jump {
  0% {
    height: 4rem;
  }

  30% {
    height: $spinner-bar-max-height;
  }

  100% {
    height: 4rem;
  }
}
