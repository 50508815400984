.no-js .u-hide-no-js {
  display: none;
}

.u-hidden-visually {
  @include hidden-visually();
}

.u-collapse[aria-expanded='false'] {
  display: none;
}

.u-collapse[aria-expanded='true'] {
  display: block;
}

.u-highlight {
  background-color: $color-blue;
  color: #fff;
  padding: 0 0.15em;
}

.u-highlight-text {
  color: $color-blue;
}

.u-highlight-content {
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    top: -50%;
    left: 70%;
    position: absolute;
    background-color: transparent;
    width: 100%;
    height: 120%;
    transform: skewX(-18deg);
    border: 3px solid rgba($color-white, 0.1);
    border-bottom-left-radius: 40px;

    @include mq($until: l) {
      top: 2%;
      left: 50%;
      width: 80%;
      height: 50%;
    }
  }
}

.u-hyphens {
  hyphens: auto;
}

.u-no-wrap {
  white-space: nowrap;
}

/* text indent e.g. for lists */
.u-text-indent {
  text-indent: -30px;
  margin-left: 30px;
  display: block;
}

.u-no-margin-bottom {
  margin-bottom: 0;

  &-important {
    margin-bottom: 0 !important;
  }
}

.u-hide-mobile {
  @include mq($until: l) {
    display: none;
  }
}

.u-hide-desktop {
  @include mq($from: l) {
    display: none;
  }
}
