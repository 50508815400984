.teaser-job {
  width: 100%;
  padding-bottom: 3rem;
  padding-top: 3rem;
  //max-width: 1000px;
}

.teaser-job__title {
  width: 50%;

  @include mq($until: m) {
    width: 100%;
  }
}

.teaser-job__headline {
  line-height: 3rem;
}

.teaser-job__inner {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;

  @include mq($until: m) {
    width: 100%;
    align-items: unset;
    flex-direction: column;
  }
}

.teaser-job__info {
  display: flex;
  width: 25%;
  min-width: 20rem;
  align-items: center;
  justify-content: space-between;

  @include mq($until: m) {
    justify-content: space-between;
    width: 100%;
    min-width: unset;
    gap: unset;
  }
}

.teaser-job__location {
  font-size: $font-size-xxs;
}

.teaser-job__button {
  min-width: 7.2rem;
  min-height: 5.1rem;

  .button {
    @include mq($until: m) {
      width: unset;
    }
  }
}
