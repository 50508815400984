/**
 * Declare all block-level element margins in the same direction.
 * See: http://csswizardry.com/2012/06/single-direction-margin-declarations
 */
address,
h1,
h2,
h3,
h4,
h5,
h6,
blockquote,
p,
pre,
dl,
dd,
ol,
ul,
figure,
figcaption,
hr,
table,
fieldset {
  margin: 0;
}

/**
 * Default headline style
 */
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
}

/**
 * Match type styles of form elements to parents
 */
button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  letter-spacing: inherit;
  padding-inline-end: 0;
  padding-inline-start: 0;
}

/**
 * Default link style for anchors and buttons
 */
a,
button {
  appearance: none;
  background-color: transparent;
  border: 0;
  color: inherit;
  cursor: pointer;
  display: inline;
  margin: 0;
  outline: none;
  padding: 0;
  text-decoration: none;
  user-select: text;
}

/**
 * Default transition time
 */
*,
*::before,
*::after {
  transition-duration: $default-transition-duration;
  transition-property: none;
}

/**
 * Content in pseudo-elements should not be read aurally
 * See: https://css-tricks.com/almanac/properties/s/speak/
 */
*::before,
*::after {
  speak: never;
}

/**
 * Remove animations and transitions if prefered
 */
@media (prefers-reduced-motion: reduce) {
  * {
    //animation-duration: 0.01ms !important;
    //animation-iteration-count: 1 !important;
    //scroll-behavior: auto !important;
    //transition-duration: 0.01ms !important;
    animation: none !important;
    transition: none !important;
    scroll-behavior: auto !important;
  }
}

/**
 * Add the correct font weight in Chrome, Edge, and Safari.
 */
b,
strong {
  font-weight: bold;
}

/**
 * Reset citation element
 */
cite {
  font-style: normal;
}
