.form-group {
  border: 0;
  flex-shrink: 0;
  padding: 0;
  width: 100%;
}

.form-group__label {
  margin-bottom: 1rem;
}

.form-group__description,
.form-group__error {
  font-size: $font-size-small;
  line-height: $line-height-small;
  margin-top: 0.8rem;
}

.form-group__description {
  color: $color-gray;
}

.form-group__error {
  color: $color-red;
}

.form-group__item {
  margin-top: 0.5rem;

  & + & {
    margin-top: 1rem;
  }

  + .form-group__description,
  + .form-group__error {
    margin-top: 1.5rem;
  }
}
