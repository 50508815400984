.breaker-number {
  &__headline {
    text-align: center;
    max-width: 70%;
    margin: 0 auto;
    display: block;

    @include mq($until: l) {
      max-width: 100%;
    }
  }

  &__content {
    margin-top: 20px;
    text-align: center;
    max-width: 70%;
    margin-left: auto;
    margin-right: auto;
    display: block;
    line-height: 28px;
    font-size: $font-size-s;

    @include mq($until: l) {
      max-width: 100%;
    }
  }

  .button {
    position: relative;
    left: 50%;
    margin-top: 45px;
    transform: translate(-50%, 0) skewX(-18deg);

    @include mq($until: l) {
      margin-top: 25px;
    }
  }

  &__inner {
    display: flex;
    justify-content: center;
    margin-top: 60px;

    @include mq($until: l) {
      display: block;
      margin-top: 40px;
    }
  }

  &__item {
    text-align: center;
    padding: 20px;
    flex: 0 0 33.33%;

    @include mq($until: l) {
      margin-bottom: 50px;
    }
  }

  &__bubble {
    border-radius: 50%;
    width: 165px;
    height: 165px;
    display: flex;
    background: $color-greenwhite-light;
    left: 50%;
    position: relative;
    transform: translate(-50%, 0);
    margin-bottom: 25px;
  }

  &__bubble-inner {
    border-radius: 50%;
    width: 155px;
    height: 155px;
    margin: auto;
    background: #fff;
    text-align: center;
    position: relative;
  }

  &__number-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    span {
      font-size: $font-size-xl;
      font-family: $root-font-headline;
      color: $color-green-dark;
    }
  }

  &__text {
    font-size: $font-size-xxs;
    color: $color-green-dark;
    font-weight: 700;
    line-height: 22px;
  }
}
/* stylelint-disable */
@for $i from 1 through 50 {
  .progress-#{$i} {
    background-image: linear-gradient(
        90 - (3.6 * $i) + deg,
        $color-greenwhite-light 50%,
        transparent 50%
      ),
      linear-gradient(
        90deg,
        $color-green-light 50%,
        $color-greenwhite-light 50%
      );
  }
}

@for $i from 51 through 100 {
  .progress-#{$i} {
    background-image: linear-gradient(
        90deg,
        $color-green-light 50%,
        transparent 50%
      ),
      linear-gradient(
        90 - (3.6 * ($i - 50)) + deg,
        $color-green-light 50%,
        $color-greenwhite-light 50%
      );
  }
}
