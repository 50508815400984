$dialog-content-width: 100rem;

.dialog {
  backdrop-filter: blur(10px);
  background-color: rgba($color-white, 0.9);
  height: 100%;
  left: 0;
  overflow: auto;
  padding: 2rem;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: z('dialog');
}

.dialog__content {
  margin: 0 auto;
  max-width: $dialog-content-width;
  width: 100%;
  background-color: $color-white;
}

.dialog__buttons {
  margin-bottom: 2rem;
  margin-left: auto;
  position: sticky;
  top: 0;
  width: max-content;
  z-index: 1;
}
