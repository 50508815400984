.link {
  position: relative;
  font-weight: 700;

  .active-overlay &,
  &:hover,
  &:focus {
    color: $color-dark;

    &::before {
      width: 100%;
    }

    .link__icon {
      left: 3px;
    }
  }

  .t-inverted & {
    color: $color-font-page-global--inverted;

    .icon {
      fill: $color-green-light;
    }
  }

  .t-inverted .active-overlay &,
  .t-inverted &:hover,
  .t-inverted &:focus {
    color: $color-font-page-global--inverted;
  }

  &::before {
    content: '';
    display: block;
    height: 2px;
    position: absolute;
    left: 0;
    width: 0%;
    bottom: -3px;
    background-color: $color-green-light;

    transition: all ease-in 0.1s;
  }
}

.link__icon {
  position: relative;
  left: 0;
  top: -1px;
  transition: all ease-in 0.1s;
  margin-right: 8px;

  .icon {
    //height: 9px;
    width: 12px;
  }
}

.link--menu {
  font-weight: 900;

  .link__icon {
    margin-left: 8px;

    .icon {
      width: 15px;
    }
  }
}

.link--inline {
  white-space: nowrap;

  .active-overlay &,
  &:hover,
  &:focus {
    border-bottom: 2px solid $color-green-light;

    .link__icon {
      left: 0;
    }
  }

  &::before {
    display: none;
  }

  .link__icon {
    margin-right: 2px;
  }
}
