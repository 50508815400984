.input-text {
  &__label {
    color: $color-green-dark;
    font-weight: 700;
    letter-spacing: 0.32px;
    pointer-events: none;
    position: absolute;
    top: 22px;
    left: 20px;
    transition: 0.2s ease all;
    font-size: $font-size-xs-mobile;
  }

  &__inner {
    position: relative;

    &::after {
      display: block;
      height: 20px;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 20px;
    }
  }

  &__icon {
    height: 25px;
    position: absolute;
    right: 39px;
    top: 5px;
    width: 25px;
  }

  &__input {
    height: 60px;
    border: 2px solid $color-gray-bg;
    border-radius: 5px;
    padding-left: 20px;

    background-color: $color-white;

    color: $color-green-dark;
    display: block;
    font-weight: 700;
    box-sizing: border-box;
    padding-top: 15px;
    margin-top: 5px;

    transition: border-bottom-width 0.2s ease-in-out;
    width: 100%;
    font-size: 16px;

    &[disabled],
    &[readonly] {
      cursor: not-allowed;
    }

    &[disabled] {
      font-weight: 400;
      opacity: 0.3;
    }

    &[aria-invalid='true'] {
      border-color: $color-error;
    }

    &::placeholder {
      color: transparent;
      font-weight: 400;
      opacity: 0.5;
    }

    &.focus-visible,
    &:hover {
      outline: 0;
    }

    &:focus,
    &[disabled='disabled'],
    &[readonly='readonly'],
    &:not(:placeholder-shown) {
      ~ label {
        font-size: 11px;
        font-weight: 700;
        opacity: 0.7;
        top: 11px;
      }
    }
  }

  .input-text__error-message {
    display: none;
  }

  // Icons
  &__status-icon {
    display: block;
    width: 30px;
    height: 30px;
    border-radius: 30px;
    background-color: $color-green-light;
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translate(0%, -50%);
    display: none;

    .icon {
      width: 10px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }

    &--error {
      background-color: $color-error;

      .icon {
        color: #fff;
      }
    }
  }

  /* MODIFIER */

  &--error {
    .input-text__label {
      padding-bottom: 3px;
    }

    .input-text__status-icon {
      &--error {
        display: block;
      }
    }

    .input-text__error-message {
      display: block;
      font-size: 14px;
      letter-spacing: 0.32px;
      line-height: 1.3;
      margin-top: 5px;
      width: calc(100% - 35px);
    }

    .input-text__error-message {
      color: $color-error;
    }

    .input-text__input {
      border-color: $color-error;
    }
  }

  &--valid {
    .input-text__input {
      //border-color: $color-green-light;
    }

    .input-text__status-icon {
      &--valid {
        display: block;
      }
    }
  }

  &--disabled {
    .input-text__label {
      opacity: 0.3;
    }
  }

  &--alternate {
    .input-text__input {
      background-color: #fff;
    }
  }
}

.form-green .formkit-input {
  background-color: #fff;
}

.input-text__input.input-text__input--show-placeholder::placeholder {
  color: inherit;
}

// stylelint-disable-next-line
textarea.input {
  padding: 1rem;
  width: 100%;
  border: 2px solid $color-gray-bg;
  border-radius: 5px;
  font-size: $font-size-xs-mobile;

  @include mq($from: m) {
    padding: 2rem;
  }
}
