$select-height: 6rem;

/*
@mixin select-arrow($bgColor: $color-blue, $color: #fff, $reverse: false) {
  @if $reverse {
    background-image: svg-url(
      '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56 56"><rect x="29" width="29" height="29" rx="14.5" transform="rotate(90 29 0)" fill="#2DFA87"/><path d="M19 13L14.5 17L10 13" stroke="#003C3C" stroke-width="2" stroke-linecap="round"/></svg>'
    );
  } @else {
  }
}
 */

.select {
  background-image: svg-url(
    '<svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="29" width="29" height="29" rx="14.5" transform="rotate(90 29 0)" fill="#2DFA87"/><path d="M19 13L14.5 17L10 13" stroke="#003C3C" stroke-width="2" stroke-linecap="round"/></svg>'
  );

  appearance: none;
  background-color: #fff;
  background-position: calc(100% - 20px);
  background-repeat: no-repeat;
  border: 2px solid $color-gray-bg;
  color: $color-green-dark;
  cursor: pointer;
  display: block;
  font-size: $font-size-xs-mobile;
  height: $select-height;
  padding: 0 calc(#{$select-height} + 1rem) 0 2rem;
  position: relative;
  text-overflow: ellipsis;
  transition-property: box-shadow;
  width: 100%;
  font-weight: 700;
  border-radius: 5px;

  &:focus {
    //@include select-arrow($color-blue, #fff);

    //border-color: $color-blue;
  }

  // Firefox will inherit background color from select, so we have to set a readable option color. Other browsers should ignore it.
  option {
    background-color: inherit;
    color: inherit;
  }

  // Hide select arrow on IE10+
  &::-ms-expand {
    display: none;
  }

  // Remove focus inner ring
  &:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #000;
  }
}

.select[aria-invalid] {
  //@include select-arrow($color-red, #fff);
  background-image: svg-url(
    '<svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="29" width="29" height="29" rx="14.5" transform="rotate(90 29 0)" fill="#E94D38"/><path d="M19 13L14.5 17L10 13" stroke="white" stroke-width="2" stroke-linecap="round"/></svg> '
  );
  border-color: $color-red;
}

.select[disabled] {
  //@include select-arrow(#fff, $color-gray);

  color: $color-gray;
  cursor: not-allowed;
}

// replacment base css
/* stylelint-disable */

.js-Dropdown {
  display: inline-block;
  width: 100%;
  position: relative;
}

.js-Dropdown-title {
  background-image: svg-url(
    '<svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="29" width="29" height="29" rx="14.5" transform="rotate(90 29 0)" fill="#2DFA87"/><path d="M19 13L14.5 17L10 13" stroke="#003C3C" stroke-width="2" stroke-linecap="round"/></svg>'
  );

  appearance: none;
  background-color: #fff;
  background-position: calc(100% - 20px);
  background-repeat: no-repeat;
  border: 2px solid $color-gray-bg;
  color: $color-green-dark;
  cursor: pointer;
  display: block;
  font-size: $font-size-xs-mobile;
  height: $select-height;
  padding: 0 calc(#{$select-height} + 1rem) 0 2rem;
  position: relative;
  text-overflow: ellipsis;
  transition-property: box-shadow;
  width: 100%;
  font-weight: 700;
  border-radius: 5px;
  text-align: left;
  hyphens: auto;
}

.js-Dropdown-title::after {
}

.js-Dropdown-list {
  background: #fff;
  border-left: 2px solid $color-gray-bg;
  border-right: 2px solid $color-gray-bg;
  border-bottom: 2px solid $color-gray-bg;
  box-sizing: border-box;
  display: none;
  height: 0;
  list-style: none;
  margin: 0;
  opacity: 0;
  padding: 0;
  position: absolute;
  transition: 0.2s linear;
  width: 100%;
  z-index: 999;
  box-sizing: border-box;
  border-radius: 0 0 5px 5px;
}

.js-Dropdown-list.is-open {
  display: block;
  height: auto;
  opacity: 1;
}

.js-Dropdown-list li {
  cursor: pointer;
  padding: 0.75em 1em;
  font-size: $font-size-xs-mobile;
  line-height: $line-height-default;
  hyphens: auto;
}

.js-Dropdown-list li:hover {
  background-color: $color-gray-bg;
}

.js-Dropdown-list li.is-selected {
  background-color: $color-gray-bg;
}

.js-Dropdown-optgroup {
  background: #444;
  border-bottom: 1px solid #a5a5a5;
  color: #ddd;
  cursor: default;
  padding: 0.5em;
  text-align: center;
}
/* stylelint-enable */
