@mixin search-magnifier() {
  background-image: svg-url(
    '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200"><path d="M91.2394844,2 C139.972806,2 179.478969,41.5061628 179.478969,90.2394844 C179.478969,111.15902 172.199212,130.378277 160.034775,145.502178 L195.21616,180.745637 C198.932628,184.468733 198.927255,190.499692 195.204159,194.21616 C191.481063,197.932628 185.450105,197.927255 181.733637,194.204159 L146.570822,158.979507 C131.436452,171.177158 112.190683,178.478969 91.2394844,178.478969 C42.5061628,178.478969 3,138.972806 3,90.2394844 C3,41.5061628 42.5061628,2 91.2394844,2 Z M91.2394844,21.0502035 C53.0272997,21.0502035 22.0502035,52.0272997 22.0502035,90.2394844 C22.0502035,128.451669 53.0272997,159.428765 91.2394844,159.428765 C110.267335,159.428765 127.501198,151.747789 140.009632,139.317276 C140.072177,139.249326 140.136987,139.182739 140.202964,139.116879 L140.343979,138.982746 C152.758788,126.476508 160.428765,109.253706 160.428765,90.2394844 C160.428765,52.0272997 129.451669,21.0502035 91.2394844,21.0502035 Z"/></svg>'
  );
  background-position: right 1.5rem center;
  background-size: 2rem;
}

.form-search {
  .input-text__input[type='search'] {
    margin-top: 0;
    padding-right: 6rem;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .input-text__input[type='search']:focus {
    //outline: none;
  }
}

.form-search__form {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: space-between;

  @include mq($until: xl) {
    flex-wrap: nowrap;
  }

  .form-group {
    flex-grow: 1;
    margin-right: 1rem;
    width: auto;

    @include mq($until: xl) {
      flex-shrink: 1;
      margin-right: 0;
    }
  }
}

.form-search__submit {
  align-items: center;
  position: absolute;
  right: 27px;
  top: 13px;
  height: 3.5rem;
  width: 3.5rem;

  font-size: $font-size-xs-mobile;

  @include mq($until: xl) {
    right: 18px;
  }
}
