.scrolly-highlight--video {
  margin: 0;
  width: auto;

  @include mq($until: l) {
    padding: 0 !important;
  }

  // reset css fromm scrolly highlight
  .scrolly-highlight__scroll-container {
    overflow: hidden !important;
    position: sticky !important;
    height: 100vh !important;

    @include mq($until: l) {
      height: 400px !important;
    }
  }

  .scrolly-highlight__scroll-container-sub {
    position: absolute !important;
    height: 100% !important;
    width: 500vw !important;
    display: flex !important;
  }

  .scrolly-highlight__scroll-container-sub {
    padding-top: 0;
    width: auto;

    @include mq($until: l) {
      width: 100vw !important;
    }
  }

  .scrolly-highlight__item--clipping-mask {
    width: auto;

    @include mq($until: l) {
      width: 100vw !important;
    }

    video {
      width: 100vw;
      object-fit: cover;
      position: absolute;
      top: 150px;
      left: 0;
      height: auto;

      @include mq($until: l) {
        display: none;
      }
    }

    .scrolly-highlight__headline {
      height: 100vh;
      padding-top: 50px;
      margin: 0;
      background-color: #fff;
      mix-blend-mode: screen;
      //font-size: 62rem;
      font-size: 75vh;
      text-align: center;
      display: inline-block;
      padding-left: 50px;
      word-wrap: normal;
      word-break: keep-all;
      white-space: nowrap;
      color: #000;

      @include mq($until: l) {
        font-size: 21rem;
        margin-top: 120px;
        position: relative;
        left: 0;
        mix-blend-mode: normal;
      }

      .headline__underline {
        text-decoration: underline;
        text-decoration-color: $color-green-light;
        /* stylelint-disable */
        text-decoration-thickness: 25px;
        /* stylelint-enable */
        text-underline-offset: 38px;
        border: 0;
      }
    }
  }

  .scrolly-highlight__blending-helper {
    display: block;
    position: absolute;
    height: 100%;
    width: 100vw;
    background-color: #fff;
    left: 0;
    z-index: 2;
    top: 0;

    @include mq($until: l) {
      display: none;
    }
  }
}
