.content__block--info-block {
  margin-top: -320px;
  z-index: 2;
  position: relative;

  @include mq($until: l) {
    margin-top: 0;
  }
}

.info-block {
  background-color: $color-green-light;
  padding-top: 290px;
  padding-bottom: 290px;
  text-align: center;

  @include mq($until: l) {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  &__inner {
    margin: 0 auto;
  }

  &__kicker {
    margin-bottom: 25px;
    position: relative;
  }

  &__text {
    text-align: center;
    font-size: 38px;
    color: $color-green-dark;
    font-family: 'ff-Clan-OT-Black';
    line-height: 46px;

    @include mq($until: l) {
      font-size: 26px;
    }
  }
}
