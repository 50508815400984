@import "../../../../node_modules/highlight.js/scss/github-dark";

pre code.hljs {
  font-size: 0.8em;
}

// Text specific vars
$font-size-text-large: rem2px($font-size-default);
$font-size-text-small: rem2px($font-size-default);

// stylelint-disable selector-max-compound-selectors, selector-no-qualifying-type
.text {
  color: $color-font-body-text;
  font-size: $font-size-default;
  font-size: fluid-size($font-size-text-small, $font-size-text-large);
  line-height: $line-height-default;

  > *:not(:last-child) {
    padding-bottom: 3.5rem;
  }

  > .table:not(:last-child) {
    margin-bottom: 4rem;
    padding-bottom: 0;
  }

  /* headline definitions */
  > .headline {
    padding-bottom: fluid-size(
      $spacer-content-headline-min,
      $spacer-content-headline-max
    );

    &:first-child {
      /* stylelint-disable-line */
      padding-top: 5rem;
    }
  }

  /* list definitions */
  ul,
  ol {
    list-style: none;
    overflow: hidden;
    padding-left: 2rem;
  }

  > ul li::before {
    content: '◼';
    color: $color-green-light;
    display: inline-block;
    width: 1.5em;
    margin-left: -1em;
    font-size: $font-size-small;
    vertical-align: middle;
  }

  > ol {
    counter-reset: step-counter;
    padding-left: 2.5rem;
  }

  > ul li,
  > ol li {
    padding-left: 0.4rem;
  }

  > ul li:not(:last-child),
  > ol li:not(:last-child) {
    margin-bottom: 1rem;
  }

  > ol li {
    counter-increment: step-counter;
  }

  > ol li::before {
    content: counter(step-counter) '.';
    position: absolute;

    font-weight: $font-weight-bold;
    color: $color-green-light;

    transform: translate(-2.6rem, 0);
  }

  > dl dt:not(:first-child) {
    margin-top: 1rem;
  }

  > dl dd {
    margin-left: 2rem;
  }

  /* link definitions */
  .link {
    .t-inverted & {
      color: $color-font-body-text--inverted;
    }

    .t-inverted .active-overlay &,
    .t-inverted &:hover,
    .t-inverted &:focus {
      color: $color-font-page-global--inverted;
    }
  }

  .t-inverted & {
    color: $color-font-body-text--inverted;
  }
}

/* modifier - small text for teaser ect. */
.text--small {
  font-size: $font-size-small;

  > *:not(:last-child) {
    padding-bottom: 2.5rem;
  }

  > .headline {
    padding-bottom: 1.5rem;

    &:first-child {
      /* stylelint-disable-line */
      padding-top: 2.5rem;
    }
  }

  > ul li:not(:last-child),
  > ol li:not(:last-child) {
    margin-bottom: 0.5rem;
  }

  > ul li::before {
    transform: translate(-2.2rem, 0.8rem);
  }
}
