/**
 * Make sure to include styles by font families correctly.
 * Correct: `font-family: 'Helvetica'; font-weight: italic;`
 * Incorrect: `font-family: 'Helvetica Italic';`
 */

@font-face {
  font-display: swap;
  font-family: 'ff-Clan-OT-Black';
  font-style: normal;
  font-weight: normal;
  src: url($public-path + 'fonts/ff_clan_ot_black-webfont.woff2') format('woff2'), url($public-path + 'fonts/ff_clan_ot_black-webfont.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: 'ff-Clan-OT-News';
  font-style: normal;
  font-weight: normal;
  src: url($public-path + 'fonts/ff_clan_ot_news-webfont.woff2') format('woff2'), url($public-path + 'fonts/ff_clan_ot_news-webfont.woff') format('woff');
}
