.testimonial {
  &__inner {
    display: flex;
  }

  &__content {
    @include container('xl');
    width: 50%;

    @include mq($until: l) {
      width: 100%;
    }
  }

  &__image {
    margin-left: fluid-size(50px, 135px);

    @include mq($until: l) {
      margin-left: 0;
      margin-top: 50px;
    }
  }

  &__headline {
    margin-top: 25px;
  }

  &__quote-wrapper {
    color: #fff;
    margin-top: 100px;
    font-family: $root-font-sans;

    @include mq($until: l) {
      margin-top: 50px;
    }
  }

  &__quote {
    display: block;
    font-size: $font-size-m;
    font-family: $root-font-headline;
    padding-bottom: 25px;
    position: relative;
    margin-bottom: 15px;
    line-height: 34px;

    &::before {
      content: '';
      display: block;
      width: 40px;
      height: 2px;
      background-color: #fff;
      opacity: 0.2;
      position: absolute;
      left: 0;
      bottom: 0;
    }
  }

  cite {
    span {
      display: block;
      font-size: $font-size-xxs;
      line-height: 22px;
    }

    .testimonial__quote-subline-1 {
      font-family: $root-font-headline;
    }

    .testimonial__quote-subline-2 {
      opacity: 0.8;
    }
  }

  @media screen and (min-width: 1920px) {
    padding-bottom: 5rem;
    padding-top: 4rem;
  }

  &__quote-list {
    margin-top: 4rem;
    &-entry {
      margin-bottom: 4rem;
    }
  }

  &__quote-list {
    margin-top: 4rem;
    &-entry-odd {
      margin-bottom: 4rem;
      .testimonial__quote-wrapper {
        margin-right: 24rem;
      }
    }
    &-entry-even {
      text-align: right;
      margin-bottom: 4rem;
      .testimonial__quote-wrapper {
        margin-left: 24rem;
      }
      ::before {
        right: 0;
        left: auto;
      }
    }
  }

}
