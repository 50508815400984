.picture {
  flex-grow: 1;
  line-height: 0;
  position: relative;
}

.picture__image {
  display: block;
  transition-property: opacity;
  width: 100%;

  .picture--cover & {
    bottom: 0;
    height: 100%;
    left: 0;
    object-fit: cover;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
  }
}
