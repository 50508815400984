.team-dropdown {
  position: relative;
  font-size: $font-size-xs-mobile;
  font-family: $root-font-sans;
}

.team-dropdown__selected {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  height: 100%;
  color: $root-color;
}

.team-dropdown__options {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
}

.team-dropdown__option {
  width: 100%;
  height: 48px;
  padding: 16px;
  color: $root-color;
  background-color: $color-white;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  cursor: pointer;

  &:hover {
    background-color: $color-gray-bg;
  }

  &--selected {
    background-color: $color-gray-bg;
  }
}

.team-dropdown__label {
  // make it only visible to screen readers
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.team-dropdown__scrollable {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  max-height: 480px;
  overflow-y: auto;
  border-radius: 5px;
  border-left: 2px solid $color-gray-bg;
  border-right: 2px solid $color-gray-bg;
  border-bottom: 2px solid $color-gray-bg;
  transition: $default-transition-duration linear;
  z-index: 10;
  scrollbar-color: $color-gray-light-bg $body-background;
}

.team-dropdown__scrollable::-webkit-scrollbar-track {
  background: $color-gray-light-bg;
}

.team-dropdown__scrollable::-webkit-scrollbar-thumb {
  background: $color-gray-light-bg;
}

.team-dropdown__scrollable::-webkit-scrollbar-thumb:hover {
  background: $color-gray-bg;
}

@media (prefers-color-scheme: dark) {
  .team-dropdown__scrollable::-webkit-scrollbar-thumb {
    background: $color-gray-bg;
  }
}
