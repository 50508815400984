.text-image-v1 {
  position: relative;
  display: grid;
  gap: 3rem;
  max-width: 1200px;

  @include mq($from: l) {
    gap: 8rem;
    grid-template-columns: 1fr 1fr;
  }
}

.text-image-v1__figure {
  padding-top: 11rem;

  .text-image-v1--right & {
    order: 1;
  }

  &,
  .text-image-v1--right & {
    @include mq($until: l) {
      order: 0;
      padding-top: 7rem;
    }
  }
}

.text-image-v1__figure::before {
  content: '';
  z-index: -1;
  top: -5rem;
  left: -40%;
  position: absolute;
  background-color: $color-green-light-bg-alpha;
  width: 100%;
  height: 120%;
  transform: skewX(18deg);
  border-bottom-right-radius: 40px;

  .text-image-v1--right & {
    content: '';
    top: -5rem;
    left: 40%;
    position: absolute;
    background-color: $color-green-light-bg-alpha;
    width: 100%;
    height: 120%;
    @include skew;
    border-bottom-left-radius: 40px;
  }

  &,
  .text-image-v1--right & {
    @include mq($until: l) {
      height: 45%;
      width: 120%;
      top: 5rem;
      transform: skewY(18deg) skewX(0deg);
      border-top-left-radius: 40px;
      border-bottom-right-radius: 40px;
      left: -3rem;
    }
  }

  .text-image-v1--slider & {
    @include mq($until: l) {
      height: 65%;
    }
  }
}

.text-image-v1__placeholder {
  order: 2;

  .text-image-v1--right & {
    order: 4;
  }
}

.text-image-v1__content {
  padding-top: 4.5rem;

  .text-image-v1--slider & {
    padding-top: 11rem;

    @include mq($until: l) {
      padding-top: 3rem;
    }
  }
}

.text-image-v1__kicker {
  margin-bottom: 2.4rem;

  .kicker {
    //box-shadow: 0 0 15px 2px rgba(0, 0, 0, 0.14);

    .text-image-v1--right & {
      box-shadow: none;
    }
  }
}

.text-image-v1__headline {
  margin-bottom: 2.5rem;
}
