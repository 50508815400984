.google-map__map {
  min-height: 65rem;
}

.google-map__infowindow {
  padding: 1rem;

  > .headline--1,
  > .headline--2,
  > .headline--3,
  > .headline--4,
  > .headline--5 {
    &:first-child { /* stylelint-disable-line */
      padding-top: 0;
    }
  }
}

.google-map__links {
  @include list-reset();
}
