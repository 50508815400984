/**
 * Define body including minimum width with overflow scroll.
 */
body {
  background-color: $body-background;
  font-size: $body-font-size;
  min-width: $body-min-width;
  overflow-x: hidden;
  overflow-y: scroll;
}
