// Headline specific vars
$font-size-h1-large: 48px;
$font-size-h1-small: 38px;
$font-size-h2-large: 38px;
$font-size-h2-small: 28px;
$font-size-h3-large: 28px;
$font-size-h3-small: 22px;
$font-size-h4-large: 22px;
$font-size-h4-small: 18px;
$font-size-h5-large: 18px;
$font-size-h5-small: 16px;
$font-size-h6-large: 14px;
$font-size-h6-small: 14px;

.headline {
  @include f-headline;

  color: $color-font-page-global;
  font-weight: $font-weight-normal;
  line-height: $line-height-small;
  overflow-wrap: break-word;
  word-wrap: break-word;

  .headline__underline {
    border-bottom: 3px solid $color-green-light;
  }

  .t-inverted & {
    color: $color-font-page-global--inverted;

    /* modifier - special color */
    &--special {
      color: $color-green-light;
    }
  }
}

.headline--1 {
  font-size: fluid-size($font-size-h1-small, $font-size-h1-large);
}

.headline--2 {
  font-size: fluid-size($font-size-h2-small, $font-size-h2-large);
}

.headline--3 {
  font-size: fluid-size($font-size-h3-small, $font-size-h3-large);
}

.headline--4 {
  font-size: fluid-size($font-size-h4-small, $font-size-h4-large);
}

.headline--5 {
  font-size: fluid-size($font-size-h5-small, $font-size-h5-large);
}

.headline--6 {
  font-size: fluid-size($font-size-h6-small, $font-size-h6-large);
}

.headline__link:hover {
  text-decoration: underline;
}

/* modifier - special color */
.headline--special {
  color: $color-green-light;
}
