.teaser-event {
  &.active-overlay {
    .teaser-event__headline-link {
      .icon {
        left: 15px;
      }
    }
  }

  &__inner {
    display: flex;

    @include mq($until: l) {
      display: block;
    }
  }

  &__image {
    max-width: 380px;
    width: 40%;

    @include mq($until: l) {
      width: 100%;
      max-width: none;
    }

    .picture__image {
      border-radius: 5px;
    }
  }

  &__content {
    width: 60%;
    padding-left: 35px;
    margin-top: 25px;

    @include mq($until: l) {
      width: 100%;
      padding-left: 0;
      margin-top: 0;
    }
  }

  &__headline-link {
    display: block;

    .icon {
      left: 10px;
      position: relative;
      top: 7px;
      transition: all 0.1s ease-in;
    }

    &:hover {
      .icon {
        left: 15px;
      }
    }

    &::after {
      content: '';
      display: block;
      clear: both;
    }
  }

  &__headline {
    float: left;
    word-break: break-word;
  }

  &__dates {
    display: flex;
    position: relative;
    top: -55px;
    left: 15px;

    @include mq($until: m) {
      top: -60px;
    }
  }

  &__date {
    height: 100px;
    width: 100px;
    @include skew;
    background-color: $color-green-light;
    border-top-right-radius: 20px;
    border-bottom-left-radius: 20px;
    text-align: center;

    @include mq($until: m) {
      height: 80px;
      width: 90px;
    }

    span {
      display: block;
      line-height: 22px;
    }
  }

  &__start-date {
    position: relative;
    z-index: 2;
  }

  &__end-date {
    background-color: $color-green-dark;
    position: relative;
    left: -15px;

    span {
      color: #fff;
    }
  }

  &__year {
    font-size: $font-size-xxs;
    padding-top: 10px;

    @include mq($until: m) {
      padding-top: 4px;
    }
  }

  &__day {
    font-size: $font-size-l;
    font-family: $root-font-headline;
    margin-top: 7px;

    @include mq($until: m) {
      margin-top: 4px;
    }
  }

  &__month {
    font-size: $font-size-xs;
    font-family: $root-font-headline;
    margin-top: 7px;

    @include mq($until: m) {
      font-size: 12px;
      margin-top: 4px;
    }
  }

  &__text {
    display: block;
    margin-top: 10px;
    font-size: $font-size-xxs;
    line-height: 22px;
  }

  &__infos {
    display: flex;
    margin-top: 25px;
    flex-wrap: wrap;

    @include mq($until: xl) {
      display: block;
    }
  }

  &__info {
    margin-right: 50px;
    margin-bottom: 15px;

    @include mq($until: xl) {
      margin-bottom: 15px;
    }
  }

  &__info-icon {
    height: 40px;
    width: 40px;
    @include skew;
    background-color: $color-green-light-bg;
    position: relative;
    display: inline-block;
    border-radius: 3px;

    .icon {
      width: 17px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) skewX(18deg);
    }
  }

  &__info-text {
    font-size: $font-size-xs;
    font-weight: 700;
    position: relative;
    top: -11px;
    margin-left: 10px;
  }
}
