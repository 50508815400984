.teaser-highlight {
  box-sizing: border-box;
  max-width: 1030px; // = 1250px skewed
  min-height: 540px;
  position: relative;
  display: inline-grid;
  padding-left: 70px;
  padding-right: 70px;

  @include mq($until: l) {
    max-width: none !important;
  }

  &__inner {
    background-color: $color-green-light-bg-alpha;
    border-top-right-radius: 30px;
    border-bottom-left-radius: 30px;
    @include skew;
    padding-left: 40px;
    padding-right: 10%;
    box-sizing: border-box;
    padding-top: 50px;
    padding-bottom: 50px;
    height: 100%;
    min-height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: stretch;
    align-content: stretch;
  }

  &__content {
    padding-right: 20%;
    position: relative;
    padding-left: 90px;
  }

  &__kicker {
    font-size: $font-size-xxs;
    margin-bottom: 15px;
    display: block;
    font-weight: 700;
  }

  &__button {
    position: absolute;
    right: 0;
    bottom: 0;
  }

  &__image {
    max-width: 460px;
    padding: 10px;

    .picture__image {
      clip-path: polygon(15% 0, 100% 0, 100% 15%, 85% 100%, 0 100%, 0 85%);
      border-radius: 0 28px;
    }
  }

  &--alternate-background {
    .teaser-highlight__inner {
      background-color: $color-green-light;
    }

    .teaser-highlight__button {
      background-color: #fff;
      border: 2px solid #fff;

      &:hover {
        background-color: transparent;
      }
    }
  }

  &--content-block {
    .teaser-highlight__kicker-headline-wrapper {
      background-color: $color-green-dark;
      @include skew;
      border-top-right-radius: 15px;
      border-bottom-left-radius: 15px;
      padding: 40px;
      padding-top: 60px;
      padding-bottom: 60px;

      @include mq($until: l) {
        transform: skewY(18deg);
        padding-bottom: 100px;
        border-radius: 15px;
        border-top-right-radius: 0;
        border-bottom-left-radius: 0;
        padding-left: 5px;
        padding-right: 35px;

        * {
          transform: skewY(-18deg);
        }
      }
    }

    .teaser-highlight__headline {
      position: relative;
      left: 1rem;
    }
  }

  &--background-image {
    position: relative;
    //overflow: hidden;

    .teaser-highlight__background {
      position: absolute;
      left: -100px;
      top: 0;
      right: -100px;
      bottom: 0;
      background-size: cover;

      @include mq($until: l) {
        top: -90px;
        bottom: -90px;
        background-position-x: -190px;
      }
    }

    .teaser-highlight__headline {
      color: #fff;
    }

    .teaser-highlight__kicker {
      color: $color-green-light;
    }
  }

  &--no-background {
    display: block;
    background: none;
    position: relative;

    @include mq($until: l) {
      padding: 0 !important;
    }

    .teaser-highlight__inner {
      background: none;
      transform: none;

      position: absolute;
      top: 50%;
      transform: translate(0%, -50%);
      padding: 0;
      display: block;
      height: auto;
      min-height: auto;
      width: 100%;
    }

    .teaser-highlight__content {
      padding: 0;
      text-align: center;
      transform: none;
    }

    .teaser-highlight__button {
      position: relative;
      margin-top: 25px;
    }
  }
}

/* MOBILE */
@include mq($until: l) {
  .teaser-highlight {
    padding-left: 0;
    padding-right: 0;
    padding-top: 80px;
    padding-bottom: 80px;

    &__inner {
      @include skew2;
      border-radius: 30px 0;
      padding: 20px;
      display: block;
      max-height: none;
      height: auto;
      padding-bottom: 140px;
    }

    &__image {
      margin-bottom: 70px;

      .figure {
        @include skew2;
        overflow: hidden;
        border-radius: 30px 0;
      }

      .picture__image {
        //clip-path: polygon(15% 0, 100% 0, 100% 15%, 80% 100%, 0 100%, 0 85%);
        //clip-path: polygon(0 0, 15% 0, 100% 15%, 100% 100%, 80% 100%, 0 80%);
        //border-radius: 0px 28px;
        clip-path: none;
        transform: scale(1.35);
      }
    }

    .teaser-highlight__button {
      position: absolute;
      right: 0;
      bottom: -80px;
      right: auto;
      left: 50%;
      transform: translate(-50%, 0%) skewY(0deg);

      border-radius: 10px;
      border-top-right-radius: 0;
      border-bottom-left-radius: 0;

      * {
        transform: skewY(0deg);
      }
    }

    &__content {
      text-align: center;
      padding: 0;
    }
  }
}
