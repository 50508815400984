.t-inverted {
  @include inverted(false);
}

.t-green {
  background-color: #1a504f;
}

.t-light-green {
  background-color: $color-green-light-bg;
}

.t-light-green-2 {
  background-color: $color-green-light-30-bg;
}

.t-dark-green {
  background-color: $color-green-dark;
}
