.text-image-contact {
  padding-bottom: 90px;

  &__inner {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-content: stretch;
    align-items: center;

    @include mq($until: l) {
      flex-direction: column-reverse;
    }
  }

  &__content {
    width: 45%;

    @include mq($until: l) {
      width: 100%;
    }
  }

  &__image {
    width: 40%;

    @include mq($until: l) {
      width: 100%;
      margin-bottom: 45px;
    }
  }

  &__headline {
    margin-bottom: 5px;
  }

  &__subline {
    font-size: $font-size-xxs;
    color: $color-green-medium;
    display: block;
    margin-bottom: 20px;
    line-height: 1.4;

    .t-inverted & {
      color: $color-green-light;
    }
  }

  &__contact {
    margin-top: 30px;
  }

  &__contact-item {
    display: inline-block;
    margin-right: 50px;
    margin-bottom: 20px;

    &:last-child {
      margin-right: 0;
    }

    &:hover {
      .text-image-contact__link-text {
        text-decoration: underline;
      }
    }
  }

  &__link-text {
    font-size: $font-size-xs;
    font-weight: 700;
    display: inline-block;
    margin-left: 10px;
    position: relative;
    top: -12px;

    .t-inverted & {
      color: #fff;
    }
  }

  &__icon-wrapper {
    @include skew;
    background-color: $color-green-light;
    height: 40px;
    width: 40px;
    display: inline-block;
    border-top-right-radius: 7px;
    border-bottom-left-radius: 7px;
    position: relative;

    svg {
      fill: $color-green-dark;
      width: 25px;
      height: 25px;
      position: absolute;
      top: 50%;
      left: 50%;
      margin-left: 4px;
      transform: skewX(18deg) translate(-50%, -50%);
    }
  }

  @include mq($until: l) {
    padding-bottom: 50px;
  }
}
