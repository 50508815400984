.social-share {
  min-height: 5.6rem;
  display: grid;
  grid-template-columns: auto auto auto;
  align-items: center;
  justify-content: start;
  background-color: $color-green-light-bg;

  @include mq($until: m) {
    justify-content: center;
  }
}

.social-share__title {
  font-size: $font-size-xxs;
  font-weight: $font-weight-bold;

  @include mq($until: m) {
    padding-left: 1rem;
  }
}

.social-share__links {
  position: relative;

  ul {
    list-style-type: none;
    padding-left: 1rem;
  }
}

.social-share__links li {
  float: left;
}

.social-share-item {
  display: block;
  padding-right: 2rem;
  text-decoration: none;

  .icon {
    height: 2.8rem;
    width: 2.8rem;

    &:hover {
      transform: scale(1.2);
      transition: 0.2s ease-in;
    }
  }

  @include mq($until: m) {
    padding-right: 1rem;
  }
}

.social-share__copied {
  display: block;
  position: absolute;
  right: -1.5rem;
  background: rgba(230, 236, 236, 0.5);
  border-radius: 5px;
  padding: 0.5rem 1rem;

  .icon {
    width: 1rem;
    height: 1rem;
  }

  @include mq($until: m) {
    padding: 0.25rem 0.5rem;
  }
}

.hidden {
  display: none;
}
