.text-image-v2--1,
.text-image-v2--2 {
  display: flex;
  align-items: center;

  @include mq($until: l) {
    flex-direction: column-reverse;
  }
}

.text-image-v2--1 {
  .text-image-v2__content {
    min-width: 45rem;
    width: 50%;
    position: relative;
    padding-top: 10rem;
    padding-left: 16rem;
    padding-right: 3rem;
    padding-bottom: 13rem;
    max-width: calc(1920px/2);

    @include mq($until: l) {
      padding: 2.5rem 1.5rem 0.5rem;
      width: 100%;
      min-width: 100%;
    }
  }

  .text-image-v2__content::before {
    content: '';
    position: absolute;
    left: 3rem;
    top: 2rem;
    @include skew;
    width: 110%;
    height: 90%;
    background-color: $color-green-light-bg-alpha;
    border-top-right-radius: 40px;
    border-bottom-left-radius: 40px;
    max-width: calc(1920px/2);

    @include mq($until: l) {
      padding-right: 0;
      padding-left: 0;
      left: 0;
      top: -15rem;
      min-height: 50rem;
      height: 175%;
      width: 100%;
      transform: skewY(-18deg) skewX(0deg);
    }

    @include mq($until: m) {
      height: 160%;
    }
  }

  .kicker {
    margin-bottom: 2.55rem;
  }

  .text-image-v2__text {
    color: $color-green-dark;
    opacity: 0.8;
    line-height: 2.8rem;
    padding-top: 1.5rem;
    padding-bottom: 2.65rem;
  }

  .text-image-v2__button {
    margin-left: 1rem;

    .button__text {
      font-weight: $font-weight-bold;
    }

    .button {
      @include mq($until: l) {
        width: 100%;
      }
    }
  }

  .text-image-v2__image {
    z-index: 2;

    .picture__image {
      border-radius: 0.5rem;
    }

    @include mq($until: l) {
      padding-left: 1.5rem;
      padding-right: 1.5rem;
    }
  }

  &.text-image-v2--1-left {
    justify-content: flex-start;
    flex-direction: row-reverse;

    .text-image-v2__content {
      width: 50%;
      padding-top: 10rem;
      padding-left: 5rem;
      padding-right: 14rem;
      padding-bottom: 13rem;

      @include mq($until: l) {
        padding: 2.5rem 1.5rem 0.5rem;
        width: 100%;
        min-width: 100%;
      }
    }

    .text-image-v2__content::before {
      left: -12rem;
      width: 110%;

      @include mq($until: l) {
        padding-right: 0;
        padding-left: 0;
        left: 0;
        top: -15rem;
        min-height: 50rem;
        height: 175%;
        width: 100%;
        transform: skewY(-18deg) skewX(0deg);
      }

      @include mq($until: m) {
        height: 160%;
      }
    }

    .text-image-v2__image {
      @include mq($until: l) {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
      }
    }

    @include mq($until: l) {
      flex-direction: column-reverse;
    }
  }

  @media screen and (min-width: 1920px) {
    &,
    &.text-image-v2--1-left {
      justify-content: center;
    }
  }

}

.text-image-v2--2 {
  .text-image-v2__content {
    min-width: 54rem;
    min-height: 64.2rem;
    width: 65%;
    position: relative;
    padding-top: 8rem;
    padding-left: 10%;
    padding-right: 5%;
    padding-bottom: 8rem;
    max-height: 64.2rem;
    max-width: calc(1920px/2);

    @include mq($until: l) {
      padding: 4.5rem 1.5rem 0.5rem;
      width: 100%;
      min-width: unset;
    }
  }

  .text-image-v2__content::before {
    content: '';
    min-width: 120rem;
    position: absolute;
    left: -35rem;
    top: 2rem;
    @include skew;
    width: 150%;
    height: 95%;
    background-color: $color-green-light-bg-alpha;
    border-top-right-radius: 40px;
    border-bottom-left-radius: 40px;

    @media screen and (min-width: 1920px) {
      height: 110%;
    }

    @include mq($until: l) {
      padding-right: 0;
      padding-left: 0;
      left: 0;
      top: -15rem;
      min-height: 50rem;
      height: 120%;
      width: 100%;
      min-width: unset;
      transform: skewY(-18deg) skewX(0deg);
    }

    @include mq($until: m) {
      height: 170%;
    }
  }

  .text-image-v2__headline {
    margin-bottom: 2rem;
  }

  .text-image-v2__text {
    margin-bottom: 7.5rem;
    line-height: 2.8rem;

    @include mq($until: 1070px) {
      margin-bottom: 3rem;
    }

    @include mq($until: m) {
      margin-bottom: 4rem;
    }
  }

  .text-image-v2__infos {
    display: flex;
    flex-direction: row;
    min-width: 45.5rem;
    gap: 3.3rem;

    @include mq($until: m) {
      flex-direction: column;
    }
  }

  .text-image-v2__infos-picture {
    width: 5.9rem;
    height: 5.9rem;
  }

  .text-image-v2__infos-headline {
    margin-top: 1.6rem;
    margin-bottom: 0.8rem;
  }

  .text-image-v2__infos-text {
    font-size: $font-size-xxs;
    line-height: 2.2rem;
    max-width: 27.5rem;
  }

  .text-image-v2__image {
    padding-top: 5%;

    .figure__media {
      .picture__image {
        clip-path: polygon(20% 0, 100% 0%, 99% 10%, 79% 100%, 0 100%, 0 91%);
        border-radius: 0 7%;
        height: 100%;
        width: auto;

        @include mq($until: l) {
          width: unset;
        }
      }
    }

    @include mq($until: l) {
      width: unset;
      padding-top: unset;
    }
  }

  &.text-image-v2--2-left {
    flex-direction: row-reverse;

    .text-image-v2__content {
      @include mq($until: l) {
        padding: 4.5rem 1.5rem 0.5rem;
        width: 100%;
        min-width: unset;
      }
    }

    .text-image-v2__content::before {
      left: -13rem;
      transform: skewX(18deg);
      border-top-left-radius: 40px;
      border-bottom-right-radius: 40px;
      border-top-right-radius: 0;
      border-bottom-left-radius: 0;

      @include mq($until: l) {
        padding-right: 0;
        padding-left: 0;
        left: 0;
        top: -15rem;
        min-height: 50rem;
        height: 120%;
        width: 100%;
        min-width: unset;
        transform: skewY(-18deg) skewX(0deg);
      }

      @include mq($until: m) {
        height: 170%;
      }
    }

    .text-image-v2__image {
      padding-top: 5%;

      .figure__media .picture__image {
        clip-path: polygon(0% 0%, 80% 0%, 99% 90%, 100% 100%, 20% 100%, 0 9%);
        border-radius: 7% 0;
        height: 100%;
        width: auto;

        @include mq($until: l) {
          clip-path: polygon(20% 0, 100% 0%, 99% 10%, 79% 100%, 0 100%, 0 91%);
          border-radius: 0 7%;
        }
      }

      @include mq($until: l) {
        padding-top: unset;
      }
    }

    @include mq($until: l) {
      flex-direction: column-reverse;
    }
  }

  @media screen and (min-width: 1920px) {
    justify-content: center;
  }

}
