// Text specific vars
$teaser-base-font-size-text-large: rem2px($font-size-m);
$teaser-base-font-size-text-small: rem2px($font-size-xs);

.teaser-base {
  &__link-wrapper {
    &:hover {
      .teaser-base__link .icon {
        left: 15px;
      }
    }
  }

  &__image {
    position: relative;

    .picture__image {
      border-radius: 10px;
    }
  }

  &__image-overlay {
    position: absolute;
    right: 15px;
    top: 15px;
    z-index: 2;
    max-width: 6.2rem;
    max-height: 6.2rem;
  }

  &__kicker {
    display: inline-block;
    margin-top: 25px;
  }

  .kicker__text {
    display: inline-block;
  }

  &__link {
    display: block;
    margin-top: 20px;
    line-height: 28px;
    font-size: fluid-size(
      $teaser-base-font-size-text-small,
      $teaser-base-font-size-text-large
    );
    font-family: $root-font-headline;

    .icon {
      position: relative;
      left: 10px;
      transition: all 0.1s ease-in;
    }
  }
}
