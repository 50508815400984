$button-height: 4rem;

.pagination__list {
  @include list-reset();

  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  position: relative;

  @include mq($until: m) {
    justify-content: normal;
  }
}

.pagination__item {
  color: $color-green-dark;
  font-weight: $font-weight-bold;
  line-height: 1;
  margin: 0 1px;

  &--ellipsis {
    width: 20px;
    display: inline-block;
    text-align: center;
  }
}

.pagination__item--prev,
.pagination__item--next {
  .button {
    background-color: $color-gray-bg;
    border-color: $color-gray-bg;
    width: 40px;
    height: 40px;

    &[aria-disabled] {
      &:hover {
        background-color: $color-gray-bg;
        border-color: $color-gray-bg;
      }
    }

    &:hover {
      background-color: $color-green-light;
      border-color: $color-green-light;
    }
  }

  /*
  @include mq($until: m) {
    position: absolute;
    top: 0;
  }

   */
}

.pagination__item--prev {
  margin-right: 0.5rem;
}

.pagination__item--next {
  margin-left: 0.5rem;
}

.pagination__link {
  align-items: center;
  background-color: transparent;
  display: inline-flex;
  justify-content: center;
  padding: 1.5rem;
  text-decoration: none;
  color: $color-green-dark;
  font-size: $font-size-xxs;
  width: 40px;
  height: 40px;

  @include skew;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;

  &:hover,
  &:focus {
    background-color: $color-greenwhite-dark;
  }

  @include mq($from: m) {
    padding: 0.75rem 1rem;
  }
}

.pagination__link--current {
  background-color: $color-green-light;

  &:hover,
  &:focus {
    background-color: $color-green-light;
    text-decoration: none;
  }
}
