.footer {
  background-color: $color-green-dark;
  padding-top: 60px;
  position: relative;
  overflow: hidden;

  // deco element
  &::before {
    @include skew;
    content: '';
    display: block;
    position: absolute;
    left: -100px;
    width: 50%;
    top: 0;
    bottom: 0;
    background-color: $color-green-medium;
    z-index: 1;
    border-top-right-radius: 40px;
    opacity: 0.7;

    @include mq($until: l) {
      bottom: auto;
      height: 460px;
      transform: skewY(10deg);
      width: 100%;
      left: 0;
      border-bottom-right-radius: 40px;
      top: -90px;
    }
  }

  &__navigation-wrapper {
    display: flex;
    margin-top: 40px;
    padding-bottom: 70px;

    @include mq($until: l) {
      display: block;
      padding-bottom: 20px;
    }
  }

  &__navigation {
    flex: 0 0 50%;

    ul {
      margin-top: 20px;
      padding-left: 0;
      column-count: 2;
      column-gap: 50px;
      padding-right: fluid-size(0, 190px);

      li {
        padding-bottom: 15px;
        list-style: none;
      }

      .link {
        display: inline-flex;
        white-space: nowrap;
      }

      .link__icon {
        min-width: 2rem;
      }

      @include mq($until: l) {
        padding-right: 0;
        column-gap: 0;
      }
    }

    @include mq($until: l) {
      margin-bottom: 140px;
    }
  }

  &__kicker {
    color: $color-font-body-text--inverted;
    font-size: $font-size-xxs;
    font-weight: 700;
  }

  &__social-navigation {
    ul {
      padding-left: 0;
      color: $color-font-body-text--inverted;
      margin-top: 20px;

      li {
        list-style: none;
        display: inline-block;
        margin-right: 23px;
        margin-bottom: 23px;
      }
    }

    a {
      transform: scale(1);

      &:hover {
        transform: scale(1.1);
        position: relative;
        display: block;
        transition: all 0.2s ease-in;
      }
    }

    .icon {
      width: 30px;
      height: auto;
      color: #fff;
    }
  }

  &__meta-navigation-wrapper {
    padding-top: 20px;
    padding-bottom: 20px;
    border-top: 2px solid $color-green-medium;

    ul {
      padding-left: 0;
    }

    li {
      display: inline-block;
      margin-right: 15px;

      a {
        color: $color-font-body-text--inverted;
        font-size: $font-size-xxs;
      }

      a:hover {
        text-decoration: underline;
      }
    }
  }
}

.footer__inner {
  @include container('xl');
  z-index: 2;
  position: relative;
}
