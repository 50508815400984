.resort-bubble {
  display: inline-block;
  position: relative;
}

.resort-bubble__ring {
  width: max-content;
  padding: 22px;
  border-radius: 50%;
  border: 2px solid $color-green-light-bg;
  transition: border $default-transition-duration ease-in-out;
  background-color: $color-white;
}

.resort-bubble__ring--hover:hover {
  border: 2px solid $color-green-light;
  cursor: pointer;

  @include mq($until: m) {
    // block hover effect on mobile
    border: 2px solid $color-green-light-bg;
  }
}

.resort-bubble__circle {
  height: 183px;
  width: 183px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: $color-green-light-bg;
  border-radius: 50%;
  padding: 32px;
  box-sizing: border-box;
  transition: background-color $default-transition-duration ease-in-out;
}

.resort-bubble__ring--hover:hover>.resort-bubble__circle {
  background-color: $color-green-light;

  @include mq($until: m) {
    // block hover effect on mobile
    background-color: $color-green-light-bg;
  }
}

.resort-bubble__text {
  text-align: center;
  max-width: 100%;
  hyphens: auto;
  font-size: $font-size-default;
  font-family: $root-font-headline;
  color: $color-green-dark;
  transition: text-decoration $default-transition-duration ease-in-out;
}

.resort-bubble__ring--hover:hover>.resort-bubble__circle>.resort-bubble__text {
  text-decoration: underline;

  @include mq($until: m) {
    // block hover effect on mobile
    text-decoration: none;
  }
}

.resort-bubble__member-indicator {
  position: absolute;
  bottom: 16px;
  right: 16px;
  height: 36px;
  width: 36px;
  border-radius: 50%;
  background-color: $color-green-dark;
  color: $color-white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: $font-size-small;
  font-weight: $font-weight-bold;
}

.resort-bubble__info-icon {
  margin-left: 4px;

  &:hover {
    cursor: pointer;
  }
}
