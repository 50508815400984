/**
 * Hides things but keeps it available for screen readers.
 *
 * @param $important Apply all styles as !important
 */
@mixin hidden-visually($important: false) {
  @if ($important == true) {
    $important: !important;
  } @else if ($important == false) {
    $important: null;
  } @else {
    @error '`#{$important}` needs to be `true` or `false`.';
  }

  clip: rect(0 0 0 0) $important;
  clip-path: inset(50%) $important;
  height: 1px $important;
  overflow: hidden $important;
  position: absolute $important;
  white-space: nowrap $important;
  width: 1px $important;
}

/**
 * Undos hidden-visually()
 *
 * @param $important Apply all styles as !important
 */
@mixin undo-hidden-visually($important: false) {
  @if ($important == true) {
    $important: !important;
  } @else if ($important == false) {
    $important: null;
  } @else {
    @error '`#{$important}` needs to be `true` or `false`.';
  }

  clip: auto $important;
  clip-path: none $important;
  height: auto $important;
  overflow: visible $important;
  position: static $important;
  white-space: normal $important;
  width: auto $important;
}
