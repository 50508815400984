.team-default {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 80px;
  padding-bottom: 80px;

  @include mq($until: l) {
    padding-top: 48px;
    padding-bottom: 48px;
  }
}

.team-default__inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @include mq($until: l) {
    padding-left: 32px;
    padding-right: 32px;
  }

  @include mq($until: m) {
    padding-left: 0;
    padding-right: 0;
  }
}

.team-default__title {
  position: relative;
  width: fit-content;
  padding: 7.5px 12px;
  margin-bottom: 10px;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    @include skew;
    background-color: $color-green-light-bg;
    z-index: -1;
    border-radius: 3px 5px;
  }
}

.team-default-title__text {
  font-weight: $font-weight-bold;
  font-size: $font-size-xxs;
}

.team-default__headline {
  font-family: $root-font-headline;
  font-size: $font-size-xl;
  padding: 15px;
  padding-left: 16px;
  padding-right: 16px;
}

.team-default__subline {
  text-align: center;
  padding-left: 16px;
  padding-right: 16px;
}

.team-default__selector {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 54px;
  padding-bottom: 54px;
  z-index: 3;

  @include mq($until: l) {
    padding-top: 32px;
    padding-bottom: 32px;
  }
}
