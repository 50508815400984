.filter {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
  width: 100%;
  //max-width: 1000px;

  > form {
    width: 100%;
  }
}

.filter__title {
  font-size: $font-size-xxs;
  font-weight: 500;
  line-height: 2.2rem;
}

.filter__inner {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 2rem;
  width: 100%;

  @include mq($until: m) {
    flex-direction: column;
    gap: 1.5rem;
  }
}

.filter__select {
  min-width: 290px;
}
