.figure__media {
  position: relative;
}

.figure__media-button {
  position: absolute;
  right: 2rem;
  top: 2rem;
  z-index: 1;

  &--video {
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
  }
}

.figure__caption {
  margin-top: 1.5rem;
}

.figure__caption-copyright {
  font-size: $font-size-small;
  line-height: $line-height-default;
}

.figure__caption-text {
  .figure__caption-copyright + & {
    margin-top: 1.5rem;
  }
}
