.teaser-publication {
  border-bottom: 1.5px rgba(151, 151, 151, 0.2) solid;
  width: 100%;
  padding-top: 3rem;
}

.teaser-publication__marks {
  display: flex;
  gap: 1rem;
  margin-bottom: 2rem;

  .kicker {
    border-radius: 3px;
  }
}

.teaser-publication__inner {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;

  @include mq($until: l) {
    flex-direction: column;
  }
}

.teaser-publication__title {
  display: flex;
  flex-direction: column;
  width: 45%;
  gap: 3.5rem;

  .headline {
    line-height: 2.8rem;
    font-size: 2.2rem;

    @include mq($until: l) {
      font-size: 1.8rem;
    }
  }

  @include mq($until: l) {
    width: 100%;
    gap: 2.5rem;
  }
}

.teaser-publication__authors {
  display: flex;
  flex-direction: row;
  gap: 3rem;
  margin-bottom: 3rem;

  @include mq($until: l) {
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 3rem;
  }
}

.teaser-publication__author {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
}

.teaser-publication__photo {
  width: 4.7rem;
  height: 4.7rem;
  border-radius: 4.7rem;

  img {
    border-radius: 4.7rem;
  }
}

.teaser-publication__author-von {
  font-size: $font-size-xxs;
}

.teaser-publication__author-text {
  font-weight: 700;
  font-size: $font-size-xxs;
}

.teaser-publication__authors-border {
  display: none;

  @include mq($until: l) {
    display: block;
    width: 4.7rem;
    border-bottom: 1.5px rgba(151, 151, 151, 0.2) solid;
    margin-bottom: 1.7rem;
  }
}

.teaser-publication__infos {
  display: flex;
  flex-direction: row;
  width: 50%;
  justify-content: space-between;

  @include mq($until: l) {
    width: 100%;
    gap: unset;
    padding-bottom: 3rem;
  }
}

.teaser-publication__details {
  font-size: $font-size-xs-mobile;
  width: 60%;
  line-height: 2.4rem;

  @include mq($until: l) {
    display: flex;
    //width: 90%;
    flex-direction: column;
    min-width: 24.3rem;
  }
}

.teaser-publication__button {
  min-width: 7.2rem;
  min-height: 5.1rem;

  .button {
    @include mq($until: m) {
      width: unset;
    }
  }

  @include mq($from: l) {
    padding-bottom: 5%;
  }
}
