// Global Colors
$color-red: #b00;
$color-blue: #00b;
$color-gray: #555;
$color-black: #000;
$color-white: #fff;
$color-dark: $color-black;

// Accso colors
//  primary
$color-green-light: #2dfa87;
$color-green-medium: #335f5f;
$color-green-dark: #003c3c;
$color-greenwhite-light: #f2f5f5;
$color-greenwhite-dark: #ccd8d8;
//  secondary
$color-error: #e64528;
//  backgrounds
$color-gray-bg: #d9e2e2;
$color-gray-light-bg: #e6ecec;
$color-green-dark-bg: #cbd7d8;
$color-green-light-bg: #d5fee7;
$color-green-light-30-bg: #B2E5D4;
$color-green-light-bg-alpha: rgba($color-green-light, 0.1);
// utility
$color-font-page-global: $color-green-dark;
$color-font-body-text: $color-green-medium;
$color-font-page-global--inverted: $color-white;
$color-font-body-text--inverted: $color-greenwhite-dark;

// Font sizes
$font-size-large: 2.8rem;
$font-size-default: 2rem;
$font-size-small: 1.4rem;

// font-size
$font-size-xxs: 1.4rem;
$font-size-xs: 1.8rem;
$font-size-s: 2rem;
$font-size-m: 2.2rem;
$font-size-l: 2.8rem;
$font-size-xl: 3.8rem;
$font-size-xxl: 4.8rem;

$font-size-xxs-mobile: 1.4rem;
$font-size-xs-mobile: 1.6rem;
$font-size-s-mobile: 2rem;
$font-size-m-mobile: 1.8rem;
$font-size-l-mobile: 2.2rem;
$font-size-xl-mobile: 2.8rem;
$font-size-xxl-mobile: 3.8rem;

// Line heights
$line-height-large: 1.6;
$line-height-default: 1.4;
$line-height-small: 1.2;

// Font weights
$font-weight-normal: normal;
$font-weight-bold: bold;

// Global type settings
$root-color: $color-green-dark;
$root-font-size: 62.5%;
$root-font-weight: $font-weight-normal;
$root-line-height: 1;
$root-font-sans-fallback: system, -apple-system, '.SFNSText-Regular',
  'San Francisco', 'Roboto', 'Segoe UI', 'Helvetica Neue', 'Lucida Grande',
  sans-serif;
$root-font-serif-fallback: Cambria, 'Hoefler Text', Utopia, 'Liberation Serif',
  'Nimbus Roman No9 L Regular', Times, 'Times New Roman', serif;

// Font types
$root-font-sans: 'ff-Clan-OT-News', $root-font-sans-fallback;
$root-font-headline: 'ff-Clan-OT-Black', $root-font-sans-fallback;

// Body settings
$body-background: #fff;
$body-font-size: $font-size-default;
$body-min-width: 320px;

// Global Z-index
// From top to bottom layer
$z-index-order: 'default', 'header', 'header-panel', 'skip-link', 'dialog',
  'cookie-consent';

// Animation, transitions
$default-transition-duration: 0.2s;
$fast-transition-duration: 0.1s;

// Spacer units
$spacer-content-vertical-min: 60px;
$spacer-content-vertical-max: 120px;
$spacer-content-headline-min: 20px;
$spacer-content-headline-max: 25px;

// Global minimum width
$global-min-width: 320px;

// Global maximum width
//$global-max-width: 1920px;
$global-max-width: 7680px;
$global-max-viewport-width: 1920px;

// Fluid-sizes widths
$fluid-size-width-min: $global-min-width;
$fluid-size-width-max: $global-max-viewport-width;

// Page header sizes
$page-header-height-big: 11.5rem;
$page-header-height-small: 8rem;

// Breakpoints
// stylelint-disable length-zero-no-unit
$mq-breakpoints: (
  xs: 0,
  s: $global-min-width,
  m: 480px,
  l: 768px,
  xl: 990px,
  xxl: $global-max-width,
  navigation: 1250px,
);

// Max container sizes
$mq-container-max-widths: (
  xs: 0,
  s: 375px,
  m: 790px,
  l: 1024px,
  xl: 1200px,
);

// Body padding
$mq-body-padding: (
  xs: 15px,
  s: 15px,
  m: 120px,
  l: 120px,
  xl: 120px,
);

// Public path
$public-path: '../' !default;

// Path to vendor assets, e.g. Photoswipe
// stylelint-disable scss/dollar-variable-pattern
// $pswp__assets-path: $public-path + 'media/photoswipe/';
