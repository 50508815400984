$checkbox-size: 2rem;
$checkbox-icon-size: 2rem;

.checkbox {
  display: inline-flex;
  font-size: $font-size-xxs;
  position: relative;
  width: 100%;
  flex-wrap: wrap;

  &:focus-within {
    @include focus-border();
  }
}

.checkbox__input {
  @include hidden-visually();

  height: $checkbox-size;
  width: $checkbox-size;
}

.checkbox__indicator {
  background-color: #fff;
  border: 2px solid $color-green-dark-bg;
  display: flex;
  flex-shrink: 0;
  height: $checkbox-size;
  justify-content: center;
  position: relative;
  transition-duration: $fast-transition-duration;
  transition-property: background-color, border-color;
  user-select: none;
  width: $checkbox-size;
  border-radius: 2px;

  .icon {
    width: 10px;
    color: $color-green-dark;
  }

  .checkbox__input:checked + & {
    //background-color: $color-green-light;
    //border-color: $color-green-light;
    color: #fff;
  }

  .checkbox__input[aria-invalid='true']:checked + & {
    background-color: $color-red;
    border-color: $color-red;
    color: #fff;
  }

  .checkbox__input[aria-invalid='true'] ~ & {
    background-color: #fff;
    border-color: $color-red;
  }

  .checkbox__input[readonly] ~ &,
  .checkbox__input[disabled] ~ &,
  .checkbox__input[readonly]:hover ~ &,
  .checkbox__input[disabled]:hover ~ & {
    background-color: $color-gray;
    border-color: $color-gray;
    opacity: 0.5;
  }
}

.checkbox__icon {
  align-self: center;
  font-size: $checkbox-icon-size;
  opacity: 0;

  .checkbox__input:checked + .checkbox__indicator & {
    opacity: 1;
  }

  /* stylelint-disable */
  .checkbox__input:not(:checked):not([disabled]):hover + .checkbox__indicator &,
  .checkbox__input:not(:checked)[aria-invalid='true']:hover
    + .checkbox__indicator
    & {
    color: $color-gray;
    opacity: 0.5;
  }
  /* stylelint-enable */
}

.checkbox__label {
  line-height: $line-height-default;
  margin-left: 1rem;

  a {
    font-weight: 700;
    text-decoration: underline;
    text-decoration-color: $color-green-light;

    &:hover {
      text-decoration: none;
    }
  }

  .checkbox__input[aria-invalid='true'] ~ & {
    //color: $color-red;
  }

  .checkbox__input[disabled] ~ & {
    color: $color-gray;
  }
}

.checkbox__error-message {
  width: 100%;
  flex: 0 0 100%;
  color: $color-red;
  display: none;
  margin-top: 10px;
  margin-bottom: 10px;
  position: relative;
}

.checkbox__input[aria-invalid='true'] ~ .checkbox__error-message {
  display: block;
}

.checkbox__indicator,
.checkbox__label {
  cursor: pointer;

  .checkbox__input[readonly] ~ & {
    cursor: default;
  }

  .checkbox__input[disabled] ~ & {
    cursor: not-allowed;
  }
}

.checkbox__label {
  cursor: default;
}
