.skip-link {
  @include hidden-visually;

  &:hover,
  &:focus,
  &:active {
    @include undo-hidden-visually;

    background-color: $color-gray;
    color: #fff;
    display: block;
    font-weight: bold;
    outline: none;
    padding: 2rem;
    position: fixed;
    text-align: center;
    width: 100%;
    z-index: z('skip-link');
  }
}
