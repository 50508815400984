.teaser-map {
  position: absolute;
  width: 100%;

  .gmnoprint,
  .gm-fullscreen-control {
    display: none;
  }
}

.teaser-map__map {
  width: 100%;
  height: 100%;
  max-height: 48.7rem;

  .google-map__map {
    min-height: 48.7rem;
  }

  @include mq($until: l) {
    display: none;

    .google-map__map {
      min-height: 69.7rem;
    }
  }

}

.teaser-map__map--mobile {
  display: none;

  @include mq($until: l) {
    display: block;
    min-height: 69.8rem;
  }
}

.teaser-map__contact {
  background-color: $color-green-dark;
  position: relative;
  z-index: 9;
  max-width: 48.2rem;
  padding: 4.5rem 3.8rem 6.2rem 3rem;
  left: 10%;
  top: -41rem;

  @include mq($until: l) {
    padding: 3rem 2rem;
    top: -68rem;
    left: 0;
    margin-left: 4%;
    margin-right: 4%;
  }
}

.teaser-map__headline {
  padding-bottom: 3.5rem;

  .headline {
    color: $color-green-light;
  }
}

.teaser-map__list {
  display: flex;
  gap: 2rem;
  flex-direction: column;
}

.teaser-map__item {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.teaser-map__icon {
  @include skew;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $color-green-light;
  border-top-right-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
  width: 5.9rem;
  height: 3.9rem;
}

.teaser-map__text {
  width: 100%;
  color: $color-white;
  font-size: $font-size-xs;
  line-height: 2.6rem;
  font-weight: 700;
}
