.person-bubble {
  display: inline-block;
}

.person-bubble__ring {
  padding: 7.5px;
  border-radius: 50%;
  border: 2px solid $color-green-light-bg;
  background-color: $color-white;

  &:hover {
    cursor: pointer;

    @include mq($until: m) {
      // block hover effect on mobile
      cursor: default;
    }
  }
}

.person-bubble__circle {
  position: relative;
}

.circle__action-bg {
  position: absolute;
  top: 0;
  right: 0;
  height: 80px;
  width: 80px;
  border-radius: 50%;
  background-color: $color-green-dark;
  opacity: 0;
  transition: opacity $default-transition-duration ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 0;
}

.person-bubble__ring:hover>.person-bubble__circle>.circle__action-bg {
  opacity: 0.8;

  @include mq($until: m) {
    // block hover effect on mobile
    opacity: 0;
  }
}

.circle__action-fg {
  position: absolute;
  top: 24px;
  right: 24px;
  bottom: 24px;
  left: 24px;
  border-radius: 50%;
  background-color: $color-green-light;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity $default-transition-duration ease-in-out;
  z-index: 1;
}

.person-bubble__ring:hover>.person-bubble__circle>.circle__action-fg {
  opacity: 1;

  @include mq($until: m) {
    // block hover effect on mobile
    opacity: 0;
  }
}

.action__icon {
  height: 16px;
  width: 16px;
}

.circle__picture {
  height: 80px;
  width: 80px;
  border-radius: 50%;
}
