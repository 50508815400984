.team-person {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: $global-max-width;
}

.team-person__back-button {
  position: absolute;
  top: 48px;
  left: 36px;
  z-index: 1;

  @include mq($until: m) {
    top: 24px;
    left: 24px;
  }
}

.team-person__back-button .button {
  background-color: $color-gray-bg;
  border: 0;

  &:hover {
    background-color: $color-gray-light-bg;
  }
}

.team-person__back-button .button .button__text {
  @include mq($until: m) {
    display: none;
  }
}

.team-person__presenter-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.team-person__presenter {
  position: relative;
  width: 800px;
  height: 600px;
  display: flex;
  justify-content: center;
  align-items: center;

  @include mq($until: m) {
    width: 800px;
    height: 450px;
  }
}

.presenter-fade-enter-active,
.presenter-fade-leave-active {
  transition: opacity 1s;
}

.overlay-fade-enter-active,
.overlay-fade-leave-active {
  transition: opacity $default-transition-duration;
}

.presenter-fade-enter-from,
.presenter-fade-leave-to,
.overlay-fade-enter-from,
.overlay-fade-leave-to {
  opacity: 0;
}

.presenter-fade-enter-to,
.presenter-fade-leave-from,
.overlay-fade-enter-to,
.overlay-fade-leave-from {
  opacity: 1;
}

.team-person__selector {
  width: 100%;
  z-index: 3;
}

.team-person__overlay {
  display: flex;
  justify-content: center;
  align-items: center;
}
