.f-sans {
  @include f-sans();
}

.f-serif {
  @include f-serif();
}

.f-headline {
  @include f-headline();
}

.f-w-normal {
  font-weight: normal;
}

.f-w-bold {
  font-weight: bold;
}

.f-s-normal {
  font-style: normal;
}

.f-s-italic {
  font-style: italic;
}
