.breadcrumb__list {
  @include list-reset();

  display: flex;
  flex-wrap: wrap;
  align-items: center;
  font-size: $font-size-small;
}

.breadcrumb__list-item {
  align-items: center;
  display: flex;
  line-height: $line-height-default;
}

.breadcrumb__item {
  color: $color-green-dark;
  line-height: 22px;

  &:any-link:hover {
    text-decoration: underline;
  }
}

.breadcrumb__spacer {
  margin: 0 0.7rem;
}
/* stylelint-disable */
span.breadcrumb__item {
  .breadcrumb__label {
    font-weight: 700;
  }
}
/* stylelint-enable */
