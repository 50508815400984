.event-infobox {
  &__item-wrapper {
    display: flex;
    gap: 33px;
    align-items: flex-start;
    flex-wrap: wrap;

    @include mq($until: l) {
      display: block;
    }
  }

  &__items {
    background-color: $color-green-light-bg;
    border-radius: 15px;
    padding: 25px;
    flex: 1 1 0;
    min-width: 270px;
    max-width: 25%;

    &::after {
      content: '';
      clear: both;
      display: block;
    }

    @include mq($until: l) {
      width: 100%;
      max-width: none;
      min-width: auto;
      margin-bottom: 20px;
    }
  }

  &__icon-wrapper {
    width: 38px;
    height: 38px;
    background-color: #fff;
    border-radius: 5px;
    position: relative;

    .icon {
      color: $color-green-dark;
      width: 20px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }

    @include mq($until: l) {
      float: left;
    }
  }

  &__text {
    margin-top: 15px;
    font-size: $font-size-xs;
    font-weight: 700;
    line-height: 26px;

    @include mq($until: l) {
      margin-top: 5px;
      margin-left: 60px;
    }
  }

  .button {
    margin-top: 25px;
  }
}
