// Navigation specific vars
$font-size-main-nav: rem2px($font-size-xs);
$font-size-main-nav-small: rem2px($font-size-xxs);
$font-size-main-nav-mobile: rem2px($font-size-default);
$font-size-sub-nav: rem2px($font-size-xs);
$font-size-sub-nav-mobile: rem2px($font-size-xs-mobile);

.navigation {
  @include mq($until: xl) {
    height: 100%;
    max-width: 100%;
    position: relative;
    transform: translateX(-100%);
    transition: transform $default-transition-duration;
    width: 100%;
  }

  @include mq($from: xl) {
    height: 100%;
    max-width: none;
    width: 100%;
  }
}

.header__navigation--open .navigation {
  @include mq($until: xl) {
    transform: translateX(0);
  }
}

.navigation__inner {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;

  @include mq($from: xl) {
    height: 100%;
    justify-content: space-between;
    padding-bottom: 0;
    padding-left: 4rem;
  }
}

.navigation__head {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 2rem;
  border-bottom: 2px solid $color-green-medium;

  @include mq($from: xl) {
    display: none;
  }
}

.navigation__logo {
  width: 22rem;

  @include mq($from: xl) {
    display: none;
  }
}

.navigation__close {
  font-size: 1.6rem;
  height: 4rem;
  width: 4rem;

  .button__icon {
    font-size: 2rem;
  }
}

.navigation__item {
  margin-bottom: 1rem;

  @include mq($from: xl) {
    margin-bottom: 0;
  }

  .t-inverted & {
    color: $color-font-page-global--inverted;
  }
}

.navigation__search-toggle {
  flex-shrink: 0;
  font-size: 1.6rem;
  position: relative;

  @include mq($until: xl) {
    display: none;
  }

  &:hover,
  &:focus {
    background-color: $color-white;
  }

  &[aria-expanded='true'] {
    z-index: 999;
  }

  .navigation__search-toggle-icon {
    width: 3.5rem;
    height: 3.5rem;
  }

  &[aria-expanded='true'] .navigation__search-toggle-icon--open {
    display: none;
  }

  .navigation__search-toggle-icon--close {
    display: none;
  }

  &[aria-expanded='true'] .navigation__search-toggle-icon--close {
    display: inline-flex;
  }
}

.navigation__item--search {
  @include mq($until: xl) {
    order: 1;
    margin-top: auto;
  }

  @include mq($from: xl) {
    break-inside: avoid;
    margin-top: -2.5rem;
    margin-right: 1rem;
    order: 1;
    page-break-inside: avoid;
    position: relative;

    &::before {
      background-color: rgba($color-gray, 0);
      bottom: 0;
      content: '';
      height: 100vh;
      left: 0;
      pointer-events: none;
      position: fixed;
      right: 0;
      top: 0;
      transition: background-color $fast-transition-duration;
      width: 100vw;
    }
  }

  ~ .navigation__item--link {
    > .navigation__link {
      @include mq($until: xl) {
        display: block;
        margin-bottom: 25px;
        font-size: $font-size-large;
        font-weight: $font-weight-bold;
      }
    }
  }

  ~ .navigation__item--main {
    > .navigation__link {
      &::after {
        @include mq($until: xl) {
          display: inline-block;
          position: absolute;
          right: 20px;
          width: 2rem;
          @include link-download-svg();
        }
      }
    }
  }
}

.navigation__item--search-open {
  @include mq($from: xl) {
    display: flex;
    flex-direction: row-reverse;
    left: calc((100vw / 2) - (75rem / 2));
    max-width: 75rem;
    position: fixed;
    width: 100vw;
    z-index: z('header-panel');

    &::before {
      background-color: rgba($color-gray, 0.9);
      pointer-events: all;
    }
  }
}

.navigation__search {
  .form__field {
    margin: 0;
  }

  @include mq($from: xl) {
    display: none;
  }
}

.navigation__search--open {
  @include mq($from: xl) {
    display: block;
    margin-right: 2rem;
    position: relative;
    width: 100%;
    z-index: 1;

    &::before {
      background-color: #fff;
      border-radius: 1rem;
      content: '';
      display: block;
      height: 100%;
      left: -3rem;
      padding: 6rem;
      position: absolute;
      top: -3rem;
      width: calc(100% + 13rem);
      z-index: -1;
    }
  }
}

.navigation__item-headline {
  margin-bottom: 2rem;

  @include f-headline;
  font-weight: $font-weight-normal;
  font-size: $font-size-default;
  line-height: $line-height-default;
}

.navigation__link {
  font-size: $font-size-default;
  text-align: left;
  text-decoration: none;

  &.link--menu {
    @include f-headline;
    white-space: nowrap;
    font-weight: $font-weight-normal;
  }

  &.navigation__link--back {
    width: calc(100% + 4rem);
    margin: 0 0 0 -2rem;
    padding: 2rem;
    background-color: $color-green-dark;

    @include f-sans();
    font-size: $font-size-xs;
    font-weight: $font-weight-bold;

    @include mq($from: xl) {
      display: none;
    }
  }

  &[aria-expanded='true'] {
    font-weight: bold;
  }
}

.navigation__item-description {
  margin-top: 1rem;
  font-size: $font-size-xs-mobile;
  line-height: $line-height-default;
}

.navigation__link--main {
  @include mq($from: xl) {
    display: block;
    font-size: fluid-size(
      $font-size-main-nav-small,
      $font-size-main-nav,
      true,
      990px,
      1440px
    );
    line-height: 1;
    padding-bottom: 2.5rem;
    position: relative;

    &:hover,
    &:focus,
    &[aria-expanded='true'],
    &.navigation__link--main-active {
      text-decoration: none;
    }

    &::after {
      content: '';
      display: inline-block;
      border-bottom: 2px solid transparent;
      transition-property: border;
      position: absolute;
      width: 100%;
      bottom: 0;
      left: 0;
      z-index: 1;
    }

    &:hover::after,
    &:focus::after,
    &[aria-expanded='true']::after,
    &.navigation__link--main-active::after {
      border-bottom-color: $color-green-light;
    }

    /* gitlab issues/72 touch adjustments - open flyout on first tap, open link on second tap */
    .touch-device & {
      &[aria-haspopup='true'] {
        pointer-events: none;

        /* allow link handling if flyout is opened */
        &[aria-expanded='true'],
        &.navigation__link--main-active {
          pointer-events: auto;
        }
      }
    }
  }
}

.navigation__link-icon {
  margin-right: 1rem;
  font-size: 0.8em;

  .navigation__link--back & {
    @include mq($until: xl) {
      color: $color-green-light;
    }
    @include mq($from: xl) {
      display: none;
    }
  }
}

.navigation__level {
  @include mq($until: xl) {
    background-color: #1a504f;
    min-height: calc(100vh - 82px);
    left: 100%;
    position: absolute;
    top: 0;
    transition-property: left;
    width: 100%;
    z-index: 1;

    &.navigation__level--open {
      left: 0;
    }
  }

  @include mq($from: xl) {
    height: 0;
    left: 0;
    max-height: 0;
    overflow: hidden;
    position: absolute;
    top: 100%;
    transition: max-height 0.7s ease-in;
    width: 100%;
    z-index: z('header-panel');

    &.navigation__level--open {
      height: auto;
      max-height: 80rem;
    }
  }
}

// Basic show dropdown on hover as long as javascript is not initialized
.navigation:not(.js-navigation-initialized) .navigation__item--main:hover,
.navigation:not(.js-navigation-initialized) .navigation__item--main:focus {
  @include mq($from: xl) {
    .navigation__level {
      height: auto;
      max-height: 80rem;
    }
  }
}

.navigation__level-inner-wrapper {
  @include mq($from: xl) {
    background-color: #fff;
  }

  @include mq($until: xl) {
    display: none;
    padding: 0 2rem;

    .navigation__level--open & {
      display: block;
    }
  }
}

/* flyout container */
.navigation__level-inner {
  @include mq($from: xl) {
    @include container();

    background-color: #fff;
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;
    padding-bottom: 4rem;
    padding-top: 4rem;
    width: 100%;
    column-gap: fluid-size(30px, 60px, true, 990px, $global-max-viewport-width);

    &::after {
      background: linear-gradient(
        180deg,
        rgba($color-gray, 0.2) 0%,
        rgba($color-gray, 0) 100%
      );
      content: '';
      height: 2rem;
      left: 0;
      position: absolute;
      right: 0;
      top: calc(100% - 2rem);
      width: 100%;
      z-index: z('header-panel');
    }
  }
}

.navigation__col-first {
  @include mq($from: xl) {
    display: none;
  }
}

.navigation__col {
  padding: 4rem 0;
  border-bottom: 2px solid $color-green-medium;

  @include mq($from: xl) {
    flex-grow: 1;
    flex-basis: 50%;
    padding: 0;
    border-bottom: 0;
  }

  &:last-child {
    border-bottom: 0;
  }

  .navigation__item {
    margin-bottom: 3rem;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.navigation__level-head {
  &.navigation__level--open {
    display: block;
  }

  @include mq($from: xl) {
    display: none;
  }
}

.navigation__items {
  @include mq($from: xl) {
    @include f-sans;
    align-items: center;
    display: flex;
    row-gap: 1rem;
    column-gap: fluid-size(15px, 35px, true, 990px, 1440px);
    justify-content: flex-end;
  }

  .navigation__level & {
    @include mq($from: xl) {
      display: block;
    }
  }
}

/* flyout nav lit with heading + icons */
.navigation__items--icons {
  .navigation__item {
    margin-bottom: 2rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .navigation__link {
    display: inline-flex;
    align-items: center;
    margin-bottom: 0 !important;

    font-size: fluid-size($font-size-sub-nav-mobile, $font-size-sub-nav);

    &::before {
      left: 60px;
      bottom: 0;
    }

    &:hover {
      &::before {
        width: calc(100% - 60px);
      }

      .link__icon {
        left: auto;
      }
    }
  }

  .link__icon {
    padding: 0 10px;
    margin-right: 15px;
    height: 4rem;

    &:hover {
      background-color: $color-green-light;
    }
  }

  .navigation__link-icon {
    width: 2rem;
    height: 2rem;
    margin-right: 0;

    .t-inverted & {
      @include mq($until: xl) {
        fill: $color-green-dark;
      }
    }
  }
}

.navigation__level .navigation__link:not(.navigation__link--back) {
  @include mq($from: xl) {
    margin-bottom: 2rem;
  }
}

.navigation__content {
  @include mq($until: xl) {
    position: relative;
    padding: 0 2rem;
    padding-top: 5rem;
    height: 100%;
  }

  > .navigation__items {
    @include mq($until: xl) {
      display: flex;
      flex-direction: column;
      height: 100%;
    }
  }
}
