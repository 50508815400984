.kicker {
  @include skew;
  @include f-sans();

  display: inline-flex;
  padding: 2px 15px;

  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-top-left-radius: 3px;
  border-bottom-right-radius: 3px;
  background-color: $color-green-light-bg;
  border: 1px solid $color-gray-bg;

  font-size: $font-size-small;
  line-height: $line-height-large;

  transition-property: background-color, color;

  /* modifier - kicker with alternate style */
  &.kicker--alternate {
    padding: 0;
    border: 0;
    background-color: unset;
    color: $color-green-light;
  }

  /* inverted theme styles */
  .t-inverted & {
    &.kicker--alternate {
      .active-overlay &,
      &:hover,
      &:focus {
        color: $color-green-light;
      }
    }
  }
}
