.content {
  &:not(:last-child) {
    margin-bottom: 10rem;
  }

  .stage + & {
    margin-top: 5rem;
  }

  .content__block:last-child:not(.content__block--craft-cookie-consent) {
    margin-bottom: fluid-size(
      $spacer-content-vertical-min,
      $spacer-content-vertical-max
    );
  }
}

.content__block:not(:last-child) {
  margin-bottom: fluid-size(
    $spacer-content-vertical-min,
    $spacer-content-vertical-max
  );
}

.content__block--narrow {
  @include container('m');
}

.content__block--wide {
  @include container('xl');
}

.content__block--full-bg {
  padding-top: fluid-size(
    $spacer-content-vertical-min,
    $spacer-content-vertical-max
  );
  padding-bottom: fluid-size(
    $spacer-content-vertical-min,
    $spacer-content-vertical-max
  );
}

.content__headline {
  margin-bottom: fluid-size(
    $spacer-content-headline-min,
    $spacer-content-headline-max
  );

  .content__block--full & {
    @include mq($until: xxl) {
      @include container('xl');
    }
  }
}

.content__block--breadcrumb {
  display: none;

  @include mq($from: m) {
    display: block;
  }
}
