$button-size: 5rem;
$button-icon-size: 2.5rem;

.button {
  @include skew;
  align-items: center;
  background-color: $color-green-light;
  color: $color-green-dark;
  display: inline-flex;
  height: $button-size;
  justify-content: center;
  padding: 0 18px;
  transition-property: background-color, color;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border: 2px solid $color-green-light;
  letter-spacing: -0.482px;
  font-size: 1.6rem;
  font-weight: 500;

  &[disabled],
  &[aria-disabled] {
    background-color: $color-gray-light-bg;
    border-color: $color-gray-light-bg;
    color: $color-green-dark;
    cursor: not-allowed;

    &:hover {
      background-color: $color-gray-light-bg;
    }

    > * {
      opacity: 0.2;
    }
  }

  .active-overlay &,
  &:hover,
  &:focus {
    background-color: #fff;
  }

  &[disabled]:hover,
  &[disabled]:focus,
  &[aria-disabled]:hover,
  &[aria-disabled]:focus {
    background-color: #fff;
  }

  .t-inverted & {
    // inverted style
  }

  .t-inverted .active-overlay &,
  .t-inverted.active-overlay &,
  .t-inverted &:hover,
  .t-inverted &:focus {
    background-color: transparent;
    color: $color-font-page-global--inverted;
  }

  .t-inverted &[disabled],
  .t-inverted &[aria-disabled] {
    //opacity: 0.2;
    color: #000;
    background-color: $color-green-medium;
    border-color: $color-green-medium;
  }

  .t-inverted &[disabled]:hover,
  .t-inverted &[disabled]:focus,
  .t-inverted &[aria-disabled]:hover,
  .t-inverted &[aria-disabled]:focus {
    background-color: transparent;
    color: $color-font-page-global--inverted;
  }

  @include mq($until: m) {
    width: 100%;
  }
}

.button--secondary {
  background: transparent;

  .active-overlay &,
  &:hover,
  &:focus {
    background-color: $color-green-light;
  }

  .t-inverted & {
    color: $color-font-page-global--inverted;

    .icon {
      path {
        fill: $color-font-page-global--inverted;
      }
    }

    &:hover {
      background-color: $color-green-light;
      color: $color-green-dark;
    }
  }

  &[disabled],
  &[aria-disabled] {
    background-color: transparent;
    border-color: $color-gray-light-bg;
    color: $color-green-dark;
    cursor: not-allowed;

    &:hover {
      background-color: $color-gray-light-bg;
    }

    > * {
      opacity: 0.2;
    }
  }

  .t-inverted &[disabled],
  .t-inverted &[aria-disabled] {
    color: $color-font-page-global--inverted;
    background: transparent;
  }
}

.button--icon-only {
  padding: 0;
  width: $button-size;
}

.button__icon {
  font-size: 1.5rem;
}

.button--mobile-no-full-width {
  @include mq($until: m) {
    width: auto;
  }
}

.button__icon + .button__text,
.button__text + .button__icon {
  margin-left: 1rem;
}

.button__text {
  .button--icon-only & {
    @include hidden-visually;
  }
}
