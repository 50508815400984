.person-box {
  width: 333px;
  display: inline-block;
}

.person-box-text-front {
  top: auto;
  bottom: 0%;
  transform: translateY(-0%);
  padding-left: 5px;
  padding-right: 5px;
}

.person-box-text-back {
  padding-left: 5px;
  padding-right: 5px;
}

.person-box-text-back .text {
  color: $color-green-light;
}

.person-tiles {
  text-align: center;
}

.person-card-back {
  background-color: $color-green-dark;
}

.person-card-front .teaser_logo__flip-button,
.person-card-back .teaser_logo__flip-back-button {
  top: 10px;
  bottom: auto;
  right: 15px;
  left: auto;
}

.person-tiles-container {
  padding-top: 2rem;
  padding-bottom: 2rem;
}
