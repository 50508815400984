$transition-delay-header: 0.3s;
$transition-duration-header: 0.3s;

.header {
  height: $page-header-height-small;
  left: 0;
  margin: 0 auto;
  margin-bottom: 5rem;
  max-width: 100vw;
  position: fixed;
  right: 0;
  top: 0;

  //background-color: $color-white;
  transition-delay: $transition-delay-header;
  transition-duration: $transition-duration-header;
  transition-property: background-color;
  z-index: z('header');

  &::before {
    content: '';
    border-bottom: 2px solid $color-greenwhite-light;
    position: absolute;
    bottom: 0;
    width: 100%;
  }

  @include mq($from: xl) {
    height: $page-header-height-big;
  }
}

/* modifier */
.header--not-top {
  background-color: $color-white;
  transition-delay: 0;

  &::after {
    background: linear-gradient(
      180deg,
      rgba($color-gray, 0.2) 0%,
      rgba($color-gray, 0) 100%
    );
    content: '';
    height: 2rem;
    left: 0;
    position: absolute;
    right: 0;
    top: 100%;
    width: 100%;
    z-index: z('header');
  }
}

.header--animated:not(.header--open-clicked) {
  animation-duration: $transition-duration-header;
  animation-fill-mode: both;
  will-change: transform, opacity;
}

.header--animated.header--slide-down {
  animation-name: slideDown;
}

.header--animated.header--slide-up {
  animation-name: slideUp;
}

.ios.menu-open .header--animated.header--slide-up {
  animation-name: none !important;
}

.header__inner {
  @include container('xl');

  align-items: center;
  display: flex;
  height: 100%;
  justify-content: space-between;
  margin: 0 auto;
}

.header__logo {
  max-width: 16.6rem;
}

.header__navigation {
  @include mq($until: xl) {
    background-color: rgba($color-gray, 0);
    bottom: 0;
    height: 100vh;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    transition: background-color $fast-transition-duration;
    visibility: hidden;
  }

  @include mq($from: xl) {
    display: block;
    height: 100%;
  }
}

.header__navigation--open {
  @include mq($until: xl) {
    background-color: rgba($color-gray, 0.9);
    overflow-x: hidden;
    overflow-y: scroll;
    visibility: visible;
    z-index: z('header-panel');
  }
}

.header__navigation-toggle-button {
  font-size: 1.6rem;
  height: 4rem;
  width: 4rem;

  @include mq($from: xl) {
    display: none;
  }

  .button__icon {
    font-size: 2rem;
  }
}

/* animations */
@keyframes slideDown {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideUp {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(-100%);
  }
}
