.team-fade-enter-active,
.team-fade-leave-active {
  transition: opacity $default-transition-duration;
}

.team-fade-enter-from,
.team-fade-leave-to {
  opacity: 0;
}

.team-fade-enter-to,
.team-fade-leave-from {
  opacity: 1;
}
