.teaser-job-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  //max-width: 1000px;

  .teaser-job {
    border-bottom: 1.5px rgba(151, 151, 151, 0.2) solid;
    padding-bottom: 3rem;
    padding-top: 3rem;
  }
}

.teaser-job-list__results {
  width: 100%;
  font-size: $font-size-xxs;
  font-weight: $font-weight-bold;
  line-height: 2.2rem;
}

.teaser-job-list__items {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.teaser-job-list__pagination,
.teaser-job-list__button {
  margin-top: 5.4rem;
}
