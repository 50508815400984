.accordion__item {
  border: 2px solid $color-greenwhite-light;
  border-radius: 5px;
  margin-bottom: 15px;
  position: relative;
  padding: 25px;
  color: $color-green-dark;
}

.accordion__item--icon {
  padding-left: 95px;

  .accordion__toggle {
    position: relative;
  }

  .accordion__item-icon {
    width: fluid-size(45px, 50px);
    height: fluid-size(45px, 50px);
    background-color: $color-green-light-bg;
    display: block;
    position: absolute;
    left: -70px;

    .icon {
      width: fluid-size(45px, 50px);
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
      height: 30px;
    }

    @include mq($until: m) {
      position: relative;
      left: 0;
      margin-bottom: 10px;
    }
  }

  @include mq($until: m) {
    padding-left: 25px;
  }
}

.accordion__toggle {
  //display: flex;
  position: relative;
  margin-bottom: 6px;
  font-size: 22px;
  font-family: $root-font-headline;
  cursor: pointer;
  padding-right: 45px;
}

.accordion__title {
  font-weight: bold;
  line-height: fluid-size(16px, $line-height-default);
  font-size: fluid-size(16px, 22px);
}

.accordion__intro {
  font-family: $root-font-sans;
  display: block;
  font-size: 14px;
  margin-top: 5px;
  line-height: $line-height-default;
}

.accordion__icon {
  transition-property: transform;
  position: absolute;
  right: 0;
  top: 15px;

  .accordion__item[open] & {
    transform: rotate(45deg);
    transform-origin: center center;
  }
}

.accordion__content {
  margin-top: 35px;
}

details > summary {
  list-style: none;
}

details > summary::-webkit-details-marker {
  display: none;
}
