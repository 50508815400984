.resort-info__overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  pointer-events: auto;
  cursor: default;
}

.resort-info {
  position: relative;
  display: inline-block;
}

.resort-info__box {
  width: 360px;
  position: absolute;
  bottom: 130%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;

  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 24px;
  padding-right: 40px;
  background-color: $color-green-dark;
  border-radius: 3px;

  @include mq($until: m) {
    width: 300px;
  }
}

.resort-info .resort-info__box::after {
  content: '';
  position: absolute;
  top: 99%;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid $color-green-dark;
}

.resort-info__button-close {
  position: absolute;
  top: -16px;
  right: -16px;
}

.resort-info__text {
  color: $color-white;
  font-size: $font-size-small;
  line-height: $line-height-small;
  font-family: $root-font-sans;
}

.tooltip-fade-enter-active,
.tooltip-fade-leave-active {
  transition: opacity $default-transition-duration;
}

.tooltip-fade-enter-from,
.tooltip-fade-leave-to {
  opacity: 0;
}

.tooltip-fade-enter-to,
.tooltip-fade-leave-from {
  opacity: 1;
}
