.navigation__meta {
  margin-top: 2rem;
  padding: 2rem;
  border-top: 2px solid $color-green-medium;
  font-size: $font-size-small;

  @include mq($from: xl) {
    margin: 2rem 1.2rem 0 0;
    padding: 0;
    order: -1;
    border-top: 0;
  }
}

.navigation__meta-items {
  display: flex;
  gap: 2.5rem;

  @include mq($from: xl) {
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: 3rem;
    justify-content: flex-end;
  }
}

.navigation__meta-item {
  .t-inverted & {
    color: $color-font-page-global--inverted;
  }
}

.navigation__meta-link {
  color: $color-green-dark;
  display: inline-block;
  text-decoration: none;

  @include mq($until: xl) {
    padding: 1.5rem 0;
  }

  &:hover,
  &:focus {
    color: $color-dark;
    text-decoration: underline;
  }

  .t-inverted & {
    color: $color-font-page-global--inverted;
  }
}

.navigation__meta-item--slogan {
  white-space: pre;

  .navigation__meta-link {
    color: $color-dark;
  }

  .u-mark {
    font: inherit;
    padding-left: 1rem;
    padding-right: 1rem;
    z-index: inherit;
  }

  @include mq($until: xl) {
    margin-top: 1rem;
    padding: 1.5rem 0;
  }
}

.navigation__meta-item--lang-select {
  .is-active,
  [aria-current='true'] {
    font-weight: $font-weight-bold;
  }
}

.lang-select__divider {
  &::before {
    content: '/';
    margin: auto 0.5rem;
  }
}
