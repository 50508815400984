.teaser-base-group {
  &__inner {
    display: flex;
    gap: 90px 35px;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;

    @include mq($until: xl) {
      display: flex;
      flex-wrap: nowrap;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 50px 35px;
    }

    @include mq($from: l, $until: xl) {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      align-items: stretch;
    }

    @include mq($until: m) {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 50px 35px
    }
  }

  &__item {
    flex: 0 0 auto;
    width: auto;
    max-width: 300px;

    @include mq($until: xl) {
      flex: 0 0 auto;
      width: auto;
      max-width: 75%;
    }

    @include mq($from: l, $until: xl) {
      flex: 0 0 auto;
      width: auto;
      max-width: 30%;
    }

    @include mq($until: m) {
      flex: 0 0 auto;
      width: auto;
      max-width: 75%;
    }
  }
}
