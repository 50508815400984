.table-of-content {
  &__list {
    list-style: none;
    display: flex;
    padding-bottom: 20px;
    border-bottom: 2px solid $color-greenwhite-dark;
    padding-left: 0;

    @include mq($until: m) {
      display: none;
    }
  }

  &__select-wrapper {
    display: block;

    @include mq($from: m) {
      display: none;
    }
  }

  &__list-item {
    align-items: flex-end;
    display: flex;
    color: $color-green-dark;
    display: block;
    font-weight: 500;
    position: relative;
    font-size: $font-size-xxs;
    margin-right: 50px;
  }
}

.table-of-content__list-item {
  &:hover {
    a {
      &::after {
        background-color: #2dfa87;
        bottom: -22px;
        content: '';
        display: block;
        height: 2px;
        left: 0;
        position: absolute;
        width: 100%;
      }
    }
  }
}
