.stage {
  position: relative;
  display: grid;
  grid-template-columns: auto auto;

  @include mq($until: l) {
    grid-template-columns: auto;
    grid-template-rows: auto auto;
  }
}

.stage__inner {
  display: flex;
  max-width: 58.5rem;
  flex-direction: column;
  min-height: 40rem;
  justify-content: flex-end;

  @include mq($until: l) {
    order: 1;
    width: 100%;
    justify-content: flex-start;
  }
}

.stage__background {
  position: absolute;
  min-width: 65rem;
  height: 65rem;
  top: -35rem;
  background-color: $color-green-light-bg-alpha;
  @include skew;
  right: -30rem;
  border-bottom-left-radius: 40px;

  .stage--with-figure & {
    top: -30rem;

    @include mq($until: l) {
      top: -10rem;
      right: -9rem;
      width: 120%;
    }
  }

  @include mq($until: l) {
    min-width: 37rem;
    top: -25rem;
    right: -7rem;
    width: 110%;
    height: 27rem;
  }
}

.stage__overline {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
  margin-bottom: 2.4rem;

  .stage--event & {
    @include mq($until: l) {
      margin-bottom: 0;
      gap: 0;
      height: 5rem;
    }
  }
}

.stage__date {
  font-size: $font-size-xxs;
  line-height: 2.2rem;
}

.stage__headline {
  margin-bottom: 1.5rem;
}

.stage__buttons {
  padding-top: 3rem;
  display: flex;
  flex-direction: row;
  gap: 2rem;

  @include mq($until: l) {
    flex-direction: column;
  }
}

.stage__authors {
  margin-top: 6rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5rem;

  @include mq($until: xl) {
    margin-top: 4rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 2rem;
  }
}

.stage__author {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
}

.stage__author-overline {
  font-size: 1.2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.stage__author-name {
  font-weight: 500;
  font-size: $font-size-xxs;
}

.stage__author-photo {
  width: 4.1rem;
  height: 4.1rem;
  border-radius: 4.1rem;

  img {
    border-radius: 4.1rem;
  }
}

.stage__figures {
  z-index: 3;
  position: relative;
  min-width: 40rem;
  min-height: 30rem;

  @include mq($until: l) {
    min-height: unset;
    min-width: unset;
  }
}

.stage__figure {
  max-width: 58.5rem;

  .picture__image,
  .video {
    clip-path: polygon(20% 0, 100% 0, 100% 12%, 80% 100%, 0 100%, 0 88%);
    border-radius: 0 10%;
  }

  @include mq($until: l) {
    order: 0;
    max-width: 34.4rem;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 2.7rem;
  }
}

.stage__figure-smaller {
  position: absolute;
  right: 0;
  bottom: -4rem;
  max-width: 27.5rem;

  .picture__image {
    clip-path: polygon(20% 0, 100% 0, 100% 15%, 80% 100%, 0 100%, 0 85%);
    border-radius: 0 10%;
  }

  @include mq($until: l) {
    max-width: 16.2rem;
    bottom: 0;
  }
}

.stage__event-dates {
  display: flex;
  position: relative;

  @include mq($until: l) {
    z-index: 4;
  }
}

.stage__event-date {
  height: 10rem;
  width: 10rem;
  @include skew;
  background-color: $color-green-light;
  border-top-right-radius: 2rem;
  border-bottom-left-radius: 2rem;
  text-align: center;

  @include mq($until: m) {
    height: 8rem;
    width: 9rem;
  }

  span {
    display: block;
    line-height: 2.2rem;
  }
}

.stage__start-date {
  position: relative;

  @include mq($until: l) {
    top: -5rem;
    z-index: 4;
  }
}

.stage__end-date {
  background-color: $color-green-dark;
  position: relative;

  left: -1.5rem;

  span {
    color: #fff;
  }

  @include mq($until: l) {
    top: -5rem;
    z-index: 3;
  }
}

.stage__year {
  font-size: $font-size-xxs;
  padding-top: 1rem;

  @include mq($until: m) {
    padding-top: 0.4rem;
  }
}

.stage__day {
  font-size: $font-size-l;
  font-family: $root-font-headline;
  margin-top: 0.7rem;

  @include mq($until: m) {
    margin-top: 0.4rem;
  }
}

.stage__month {
  font-size: $font-size-xs;
  font-family: $root-font-headline;
  margin-top: 0.7rem;

  @include mq($until: m) {
    font-size: 1.2rem;
    margin-top: 0.4rem;
  }
}
