/* stylelint-disable */
@mixin container(
  $breakpoints: map-keys($mq-container-max-widths),
  $container-max-widths: $mq-container-max-widths,
  $body-padding: $mq-body-padding
) {
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;

  @each $breakpoint, $container-max-width in $container-max-widths {
    @include mq($from: $breakpoint) {
      //padding-left: map-get($body-padding, $breakpoint);
      //padding-right: map-get($body-padding, $breakpoint);
      padding-left: fluid-size(
        map-get($body-padding, xs),
        map-get($body-padding, xl),
        true
      );
      padding-right: fluid-size(
        map-get($body-padding, xs),
        map-get($body-padding, xl),
        true
      );

      @if index($breakpoints, $breakpoint) {
        //$width: map-get($container-max-widths, $breakpoint) + (map-get($body-padding, $breakpoint) * 2);
        //width: px2rem($width);
        $width: fluid-size(
          map-get($container-max-widths, $breakpoint) +
            (map-get($body-padding, xs) * 2),
          map-get($container-max-widths, $breakpoint) +
            (map-get($body-padding, xl) * 2),
          true
        );
        width: $width;
      }
    }
  }
}
/* stylelint-enable */

@mixin container-full {
  margin-left: -(map-get($mq-body-padding, s));
  margin-right: -(map-get($mq-body-padding, s));
  max-width: none;

  @each $breakpoint, $_ in $mq-body-padding {
    @include mq($from: $breakpoint) {
      $padding: map-get($mq-body-padding, $breakpoint);

      margin-left: -($padding);
      margin-right: -($padding);
    }
  }
}

@mixin container-max {
  margin-left: auto;
  margin-right: auto;
  //max-width: px2rem($global-max-width);
  max-width: none;
}
