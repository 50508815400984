/**
 * Text selection/focus colors for inverted color themes
 */
@mixin inverted($self: 'true', $highlight-color: #fff, $text-color: $color-blue) {
  @if $self {
    &::selection {
      background-color: $highlight-color;
      color: $text-color;
    }

    &:focus {
      outline-color: $highlight-color;
    }
  } @else {
    ::selection {
      background-color: $highlight-color;
      color: $text-color;
    }

    :focus {
      outline-color: $highlight-color;
    }
  }
}
