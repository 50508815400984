.team-presenter-panzoom {
  width: 100%;
  height: 100%;
  overflow: visible;
}

.team-presenter {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  &__outer-component {
    position: absolute;
  }

  &__lines {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 400px;
    height: 400px;
    transform: translate(-50%, -50%);
    z-index: -1;
    stroke: $color-green-light-bg;
    stroke-width: 2px;
    fill: none;
  }
}
