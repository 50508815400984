.team-selector {
  max-width: 1000px;
  height: 280px;
  background-color: $color-green-dark;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  padding: 64px;

  &--full-width {
    width: 100%;
    max-width: 100%;
    height: 120px;
    flex-direction: row;
    border-radius: 0;

    @include mq($until: l) {
      width: 100%;
      height: auto;
      flex-direction: row;
      padding: 16px;
    }
  }

  @include mq($until: l) {
    width: 100%;
    height: auto;
    flex-direction: row;
    padding: 32px 16px;
  }

  @include mq($until: m) {
    border-radius: 0;
  }
}

.team-selector__inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 36px;

  &--full-width {
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;

    @include mq($until: xl) {
      display: flex;
    }
  }

  @include mq($until: l) {
    width: 100%;
    display: flex;
  }
}

.team-selector__message {
  font-size: $font-size-l;
  font-family: $root-font-headline;
  color: $color-white;
  text-align: center;

  &--full-width {
    @include mq($until: xxl) {
      font-size: $font-size-m;
    }
  }

  &--full-width {
    @include mq($until: xl) {
      display: none;
    }
  }
}

.team-selector__selection {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 32px;

  @include mq($until: l) {
    width: 100%;
    flex-direction: column;
    gap: 8px;
  }

  @include mq($until: xl) {
    gap: 16px;
  }
}

.team-selector__select {
  width: 380px;
  height: 64px;

  @include mq($until: xxl) {
    max-width: 300px;
  }

  @include mq($until: l) {
    width: 100%;
    max-width: 380px;
  }
}
