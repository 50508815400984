/**
 * Returns url() with an encoded SVG data-uri
 *
 * @param $svg Source SVG
 * @return Inline encoded data value
 */
@function svg-url($svg) {
  // Chunk up string in order to avoid "stack level too deep" error
  $encoded: '';
  $slice: 2000;
  $index: 0;
  $loops: ceil(str-length($svg) / $slice);

  // Encode
  @for $i from 1 through $loops {
    $chunk: str-slice($svg, $index, $index + $slice - 1);
    $chunk: svg-str-replace($chunk, '"', "'");
    $chunk: svg-str-replace($chunk, '<', '%3C');
    $chunk: svg-str-replace($chunk, '>', '%3E');
    $chunk: svg-str-replace($chunk, '&', '%26');
    $chunk: svg-str-replace($chunk, '#', '%23');
    $encoded: #{$encoded}#{$chunk};
    $index: $index + $slice;
  }

  @return url('data:image/svg+xml;charset=utf8,#{$encoded}');
}

/**
 * Replaces characters in a string
 *
 * @private
 * @param $string Input value
 * @param $search Value to search
 * @param $replace Value to replace with
 * @return Output value
 */
@function svg-str-replace($string, $search, $replace: '') {
  $index: str-index($string, $search);

  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace +
      svg-str-replace(
        str-slice($string, $index + str-length($search)),
        $search,
        $replace
      );
  }

  @return $string;
}

/* stylelint-disable */
@mixin link-download-svg($color: $color-green-light) {
  content: svg-url(
    '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64"><g fill="' +
      $color +
      '" fill-rule="nonzero"><path d="M38.849 5.223 64 32.5 38.849 59.777a3.793 3.793 0 0 1-5.364.215 3.803 3.803 0 0 1-.214-5.37L50.163 36.3 3.796 36.3A3.798 3.798 0 0 1 0 32.5c0-2.099 1.7-3.8 3.796-3.8h46.367L33.27 10.377a3.804 3.804 0 0 1-.116-5.024l.33-.345a3.793 3.793 0 0 1 5.364.215Z"/></g></svg>'
  );
}
/* stylelint-enable */
