/* typography mixins */

@mixin f-sans() {
  @if variable-exists(root-font-sans) {
    font-family: $root-font-sans;
  }
}

@mixin f-serif() {
  @if variable-exists(root-font-serif) {
    font-family: $root-font-serif;
  }
}

@mixin f-headline() {
  @if variable-exists(root-font-headline) {
    font-family: $root-font-headline;
  }
}

// break too long words
@mixin to-hyphenate() {
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word; /* Non standard for webkit */
  hyphens: auto;
}

@mixin to-no-hyphenate() {
  overflow-wrap: normal;
  word-wrap: normal;
  word-break: normal; /* Non standard for webkit */
  hyphens: none;
}
