.video {
  position: relative;
  height: 100%;
  width: 100%;
  display: block;
}

.video__window {
  aspect-ratio: 16 / 9; // stylelint-disable-line
  height: 100%;
  width: 100%;
  background: $color-green-light-bg;
  z-index: 0;
}

.video__mod-hidden {
  display: none;
}

.video__window .video__iframe {
  width: 100%;
  height: 100%;
}

.video__overlay-inner {
  z-index: 25;
  display: flex;
  height: 100%;
  width: 100%;
  background-color: $color-green-light-bg;
  flex-direction: column;
  align-items: center;
  padding: 10% 15%;

  @include mq($until: l) {
    .headline {
      text-align: center;
      font-size: $font-size-m;
    }
  }
}

.video__overlay-text {
  padding-top: 1.5rem;
  padding-bottom: 2.4rem;
  font-size: $font-size-s;
  line-height: 2.8rem;
  opacity: 0.8;
  text-align: center;

  @include mq($until: l) {
    padding-bottom: 3.6rem;
  }
}

.video__buttons {
  display: flex;
  justify-content: center;
  width: 100%;
  gap: 1rem;

  .button__text {
    font-weight: bold;
  }

  @include mq($until: l) {
    flex-direction: column;
  }
}
