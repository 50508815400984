.teaser-download {
  display: flex;
  align-items: center;
  @include mq($until: l) {
    flex-direction: column-reverse;
  }
}

.teaser-download__content {
  min-width: 45rem;
  width: 55%;
  position: relative;
  padding-top: 13rem;
  padding-left: 16rem;
  padding-right: 3rem;
  padding-bottom: 13rem;
  @include mq($until: l) {
    padding: 2.5rem 1.5rem 0.5rem;
    width: 100%;
    min-width: 100%;
  }
}

.teaser-download__content::before {
  content: '';
  position: absolute;
  left: 3rem;
  top: 2rem;
  @include skew;
  width: 110%;
  height: 90%;
  background-color: $color-green-light-bg-alpha;
  border-top-right-radius: 40px;
  border-bottom-left-radius: 40px;

  @include mq($until: l) {
    padding-right: 0;
    padding-left: 0;
    left: 0;
    top: -15rem;
    min-height: 50rem;
    height: 175%;
    width: 100%;
    transform: skewY(18deg) skewX(0deg);
  }
}

.teaser-download__text {
  color: $color-green-dark;
  opacity: 0.8;
  line-height: 2.8rem;
  padding-top: 1.5rem;
  padding-bottom: 2.65rem;
}

.teaser-download__button {
  margin-left: 1rem;

  .button__text {
    font-weight: $font-weight-bold;
  }

  .button {
    @include mq($until: l) {
      width: 100%;
    }
  }
}

.teaser-download__image {
  z-index: 2;

  .picture__image {
    border-radius: 0.5rem;
  }

  @include mq($until: l) {
    min-width: unset;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}
