/**
 * Defines default sizing for media elements. See also `sanitize.css/assets.css`.
 */
audio,
canvas,
picture,
svg {
  height: auto;
  max-width: 100%;
}
