@mixin focus-border() {
  outline: 2px solid $color-gray;
  outline-offset: 3px;
}

button:-moz-focusring,
[type='button']:-moz-focusring,
[type='reset']:-moz-focusring,
[type='submit']:-moz-focusring {
  @include focus-border();
}

:focus {
  @include focus-border();
}

:focus:not(:focus-visible) {
  outline: 0;
}
