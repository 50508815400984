/**
 * 1. Set default type styles for the project.
 * 2. Ensure the page always fills at least the entire height of the viewport.
 * 3. Ensure smooth scrolling anchors with URI fragments.
 */
// stylelint-disable order/properties-alphabetical-order
html {
  color: $color-font-page-global; // 1
  font-family: $root-font-sans;
  font-size: $root-font-size; // 1
  font-weight: $root-font-weight; // 1
  line-height: $root-line-height; // 1
  min-height: 100vh; // 2
  scroll-behavior: smooth;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

.has-overlay {
  overflow: hidden;
}
