.form__headline {
  margin-bottom: 2rem;
}

.form__text {
  margin-bottom: 3rem;
}

.form__row--5050 {
  @include mq($from: m) {
    display: flex;

    .form__field {
      width: 50%;
    }

    .form__field:first-child {
      margin-right: 1rem;
    }

    .form__field:last-child {
      margin-left: 1rem;
    }
  }
}

.form__field {
  margin-bottom: 3rem;
  width: 100%;
}


.form__buttons {
  display: flex;
  flex-wrap: wrap;
  margin-top: 3rem;
}

.form__button {
  margin-right: 2rem;
  margin-top: 2rem;

  &:last-child {
    margin-right: 0;
  }
}
