.page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding-top: $page-header-height-small;

  @include mq($from: xl) {
    padding-top: $page-header-height-big;
  }
}

.page__content {
  @include container-max();

  flex-grow: 1;
  width: 100%;
  padding-top: 2rem;
}
