.navigation__teaser {
  position: relative;
  padding: fluid-size(25px, 30px);
  border-radius: 5px;

  .navigation__teaser-inner {
    display: flex;
    gap: 3rem;
  }

  .navigation__teaser-content {
    flex-grow: 1;
    max-width: 100%;
  }

  .navigation__teaser-kicker {
    font-size: $font-size-small;
    padding-bottom: 0;
    margin-bottom: 1.5rem;
  }

  .navigation__teaser-headline {
    padding-bottom: 1.5rem !important;
    font-size: $font-size-default;

    &:first-child {
      padding-top: 0 !important;
    }
  }

  .navigation__teaser-text {
    font-size: $font-size-xs-mobile;
  }

  .navigation__teaser-footer {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-top: 45px;
  }

  .navigation__teaser-picture {
    flex-grow: 0;
    flex-shrink: 0;
  }

  .picture__image {
    @include skew-clip;
    height: 100%;
    width: auto;
  }
}
