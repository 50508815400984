@mixin skew {
  transform: skewX(-18deg);
  /* stylelint-disable */
  > * {
    transform: skewX(18deg);
  }
  /* stylelint-enable */
}

@mixin skew2 {
  transform: skewY(-18deg);
  /* stylelint-disable */
  > * {
    transform: skewY(18deg);
  }
  /* stylelint-enable */
}

@mixin skew-clip {
  clip-path: polygon(20% 0, 100% 0, 100% 15%, 80% 100%, 0 100%, 0 85%);
  border-radius: 0 10%;
}
