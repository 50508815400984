.text-image-slider {
  &--image-left {
    &::before {
      content: '';
      z-index: -1;
      top: -5rem;
      left: -40%;
      position: absolute;
      background-color: $color-green-light-bg-alpha;
      width: 100%;
      height: 120%;
      transform: skewX(18deg);
      border-bottom-right-radius: 40px;
    }
  }

  &--image-right {
    &::before {
      content: '';
      top: -5rem;
      left: 40%;
      position: absolute;
      background-color: $color-green-light-bg-alpha;
      width: 100%;
      height: 120%;
      transform: skewX(-18deg);
      border-bottom-left-radius: 40px;
    }
  }

  &__wrapper {
    padding: 30px 0 10px;
  }

  .text-image-v1__figure::before {
    display: none;
  }

  .text-image-v1--slider {
    margin: 0 auto;
  }

  .tns-nav {
    z-index: 2;
    justify-content: left;
    width: 100%;
    position: absolute;
    bottom: 0;
    max-width: 1200px;
    margin: 0 auto;
    left: 50%;
    transform: translate(-50%, 0%);
  }

  .text-image-slider__controls {
    position: relative;
    bottom: 0;
    max-width: 1200px;
    height: 60px;
    width: calc(50% - 40px);

    @media screen and (min-width: 1920px) {
      left: 50%;
      transform: translateX(-100%);
    }

    @include mq($until: l) {
      width: 100%;
    }

    .slider__button--prev {
      left: auto;
      right: 60px;
      z-index: 3;
    }

    .slider__button--next {
      right: 0;
      z-index: 3;
    }
  }
}
