$slider-button-icon-size: 3rem;
$slider-dot-size: 2rem;

.slider {
  position: relative;
}

.slider__buttons,
.slider__controls {
  width: 100%;
}

.slider__button {
  background-color: transparent;
  color: #fff;
  font-size: $slider-button-icon-size;
  padding: calc(#{$slider-button-icon-size} / 2);
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transition-property: background-color;
  z-index: 1;

  &:hover {
    background-color: $color-green-medium;
  }
}

.slider__button--prev {
  left: 0;
}

.slider__button--next {
  right: 0;
}

.slider__dots-list,
.tns-nav {
  margin-top: 2rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.slider__dots-item:not(:last-child),
.tns-nav button:not(:last-child) {
  margin-right: 1rem;
}

/* stylelint-disable */
.tns-nav button {
  border: 1px solid $color-green-medium;
  border-radius: 50%;
  display: block;
  height: $slider-dot-size;
  transition-property: background-color;
  width: $slider-dot-size;

  &[aria-current='true'],
  &.tns-nav-active {
    background-color: $color-green-medium;
    color: #fff;
  }

  .t-inverted & {
    border-color: #fff;
  }

  .t-inverted &[aria-current='true'],
  .t-inverted &.tns-nav-active {
    background-color: #fff;
  }
}
/* stylelint-enable */

.slider {
  .slider__button {
    background-color: $color-greenwhite-dark;
    height: 50px;
    width: 50px;
    @include skew;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    color: $color-green-dark;
    left: -10px;

    &:hover {
      background-color: $color-green-dark;
      color: #fff;
    }

    .icon {
      position: relative;
      top: -5px;
    }
  }

  .slider__button--next {
    left: auto;
    right: -10px;
  }

  .tns-nav {
    button {
      width: 10px;
      height: 10px;
      background-color: $color-greenwhite-dark;
      border: transparent;
      transition: width 0.2s ease-in;
      will-change: width;
      border-radius: 10px;
      margin-right: 5px;
    }

    .tns-nav-active {
      background-color: $color-green-light;
      width: 75px;
    }
  }
}
