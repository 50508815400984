.scrolly-highlight {
  height: 4500px; // 900

  @media screen and (min-width: 1920px) {
    margin: calc(-.5*(100vh - 1080px)) 0;
  }

  .content__block--stage & {
    margin: 0;
    @media screen and (min-width: 1920px) {
      margin: 0;
    }
  }

  @include mq($until: l) {
    padding: 0 30px;
    height: auto;
  }
}

.scrolly-highlight__scroll-container {
  overflow: hidden;
  position: sticky;
  top: 0;
  height: 100vh;

  @include mq($until: l) {
    overflow: visible;
    position: relative;
    height: auto;
  }
}

.scrolly-highlight__scroll-container-sub {
  position: absolute;
  top: 0;
  height: 100%;
  width: 500vw;
  will-change: transform;
  display: flex;
  padding-top: 120px;

  .scrolly-highlight:not(.scrolly-highlight--video) & {
    @media screen and (min-width: 1920px) {
      align-items: center;
      padding-left: calc(50vh - 1080px/2);
      padding-top: 0;
    }
  }

  @include mq($until: l) {
    position: relative;
    height: auto;
    width: auto;
    display: block;
  }
}

.scrolly-highlight__item {
  width: 1250px;
  height: 80%;
  object-fit: cover;
  object-position: center;
  //background-color: green;
  //border: 2px solid red;

  @media screen and (min-width: 1920px) {
    height: auto;
  }

  @include mq($until: l) {
    width: 100%;
    height: auto;
    margin-top: -120px;
  }

  .teaser-highlight {
    width: 1250px;
    max-width: none;

    @include mq($until: l) {
      width: 100%;
    }
  }

  &--intro {
    width: 600px;
    max-width: 600px;
    position: relative;
    display: flex;
    padding-bottom: 50px;
    /* stylelint-disable */
    padding-left: clamp(
      calc((15 / 10) * 1rem),
      calc(
        (calc((100 * (120 - 15)) / (1920 - 375)) * 1vw) +
          ((calc(((375 * 120) - (1920 * 15)) / (375 - 1920)) / 10) * 1rem)
      ),
      calc((120 / 10) * 1rem)
    );
    /* stylelint-enable */

    @include mq($until: l) {
      margin-bottom: 110px;
      text-align: center;
      max-width: 450px;
      width: auto;
    }

    .headline {
      width: 600px;
      align-self: center;
    }
  }

  &--outro {
    width: 900px;

    @include mq($until: l) {
      margin-top: 20px;
      width: auto;
    }

    .teaser-highlight {
      width: 900px;

      @include mq($until: l) {
        width: auto;
      }
    }

    .teaser-highlight__inner {
      @include mq($until: l) {
        min-height: inherit;
      }
    }

    .teaser-highlight__button {
      @include mq($until: l) {
        transform: translate(-50%, 0%) skewX(-18deg);
        border-radius: 0;
        border-top-right-radius: 10px;
        border-bottom-left-radius: 10px;

        * {
          transform: skewX(18deg);
        }
      }
    }
  }
}

// animation

.scrolly-highlight {
  .teaser-highlight__image {
    position: relative;
  }
}
