.teaser_logo__card-inner {
  text-align: center;
  transform-style: preserve-3d;
  transition: transform 0.6s ease;
}

.teaser_logo__card-front, .teaser_logo__card-back {
  overflow: hidden;
  border-radius: 0.5rem;
  width: 100%;
  height: 100%;
  position: absolute;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.teaser_logo__card-front {
  transform: rotateY(0deg);
}

.teaser_logo__card-front::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50%;
  pointer-events: none;
}

.teaser_logo__card-front img {
  position: relative;
  width: 100%;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.teaser_logo__card-back {
  transform: rotateY(180deg);
  background-color: $color-green-light-bg;
  padding: 20px;
}

.teaser_logo__card.is-flipped .teaser_logo__card-inner {
  transform: rotateY(180deg);
}

.teaser_logo__card.is-flipped .teaser_logo__card-front {
  transform: rotateY(0deg);
}

.teaser_logo__card.is-flipped .teaser_logo__card-back {
  transform: rotateY(180deg);
  display: block;
}

.teaser_logo__flip-button, .teaser_logo__flip-back-button {
  position: absolute;
  border: 2px solid $color-green-light;
  transition-property: background-color, color;
  border-radius: 0 10px 0 10px;
  bottom: 10px;
  left: 40%;
  color: $color-green-dark;
  transform: skewX(-18deg);
  background-color: $color-green-light;
  cursor: pointer;
  z-index: 99;
}

.teaser_logo__flip-button:hover, .teaser_logo__flip-back-button:hover {
  background-color: $color-greenwhite-light;
  border: 2px solid $color-green-light;
  color: $color-green-dark;
}

.teaser_logo__flip-button .button__icon, .teaser_logo__flip-back-button .button__icon {
  font-size: 2rem;
}

@media (min-width: 767px) {
  .teaser_logo__flip-button {
    display: none;
  }

  .teaser_logo__flip-back-button {
    display: none;
  }
}

@media (max-width: 768px) {
  .teaser-logo-slider__wrapper {
    display: flex;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    flex-direction: row;
  }

  .teaser_logo__card {
    margin: 20px auto;
    max-width: 34.5rem;
    min-height: 34.5rem;
    /* width: 300px; */
    /* height: 350px; */
    perspective: 1000px;
    background-color: transparent;
  }
}

.teaser-logo__inner {
  max-width: 500px;
  aspect-ratio: 1 / 1;
  display: flex;
  gap: 1.8rem;
  flex-direction: column;
  align-items: center;
  padding-left: 3.4rem;
  padding-right: 3.4rem;
  justify-content: center;
  box-shadow: 0 0 10px 4px rgba(0, 0, 0, 0.09);
  margin: 20px;

  @include mq($until: l) {
    max-width: 34.5rem;
    min-height: 34.5rem;
    margin: 20px auto;
  }
}

.teaser-logo__text {
  font-size: $font-size-xxs;
  line-height: 2.2rem;
  text-align: center;
  padding-bottom: 1rem;
}

.teaser-logo__image {
  padding-top: 1rem;
}

.teaser-logo__flip-text-front {
  position: absolute;
  top: 50%;
  width: 100%;
  -ms-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 1;
  padding: 15px 35px;
  background-color: rgba(0, 0, 0, .4);
}

.teaser-logo__flip-text-front > .text {
  color: $color-greenwhite-light;
}

.teaser-logo__flip-text-back {
  text-align: center;
  position: relative;
  top: 50%;
  -ms-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  padding: 15px 35px;
}
